// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Util$BsConsole = require("../../util.js");
var ApiWf$BsConsole = require("./ApiWf.js");
var Task2$BsConsole = require("../../Task2.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("../../Uncaught.js");
var Backtrace$BsConsole = require("../../bindings/Backtrace.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var WorkflowVersion$BsConsole = require("../WorkflowVersion.js");

function stringToType(param) {
  switch (param) {
    case "jira" :
        return /* JiraCloud */0;
    case "jira-server" :
        return /* JiraServer */1;
    default:
      throw [
            Caml_builtin_exceptions.assert_failure,
            /* tuple */[
              "ApiWfIntegrations.re",
              11,
              13
            ]
          ];
  }
}

function typeToPluginId(param) {
  if (param) {
    return "jira-server";
  } else {
    return "jira";
  }
}

var JiraType = {
  stringToType: stringToType,
  typeToPluginId: typeToPluginId
};

function fromJson(json) {
  return {
          attribute: Json_decode.field("attribute", Json_decode.string, json),
          regex: Json_decode.field("regex", Json_decode.string, json)
        };
}

function toJson(conditions) {
  var encodeCondition = function (condition) {
    var regex = condition.regex;
    var attribute = condition.attribute;
    if (attribute !== "" && regex !== "") {
      return Json_encode.object_(/* :: */[
                  /* tuple */[
                    "attribute",
                    attribute
                  ],
                  /* :: */[
                    /* tuple */[
                      "regex",
                      regex
                    ],
                    /* [] */0
                  ]
                ]);
    } else {
      return Json_encode.object_(/* [] */0);
    }
  };
  return Json_encode.array(encodeCondition, conditions);
}

var Conditions = {
  fromJson: fromJson,
  toJson: toJson
};

function fromJson$1(json) {
  return {
          attribute: Json_decode.field("attribute", Json_decode.string, json),
          jiraFieldKey: Json_decode.field("jiraFieldKey", Json_decode.string, json),
          regex: Json_decode.field("regex", Json_decode.string, json),
          conditions: Json_decode.optional((function (param) {
                  return Json_decode.field("conditions", (function (param) {
                                return Json_decode.array(fromJson, param);
                              }), param);
                }), json)
        };
}

function toJson$1(resolvedUntilOptions) {
  var encodeResolvedUntilOption = function (resolvedUntilOption) {
    var conditions = resolvedUntilOption.conditions;
    if (conditions !== undefined) {
      return Json_encode.object_(/* :: */[
                  /* tuple */[
                    "attribute",
                    resolvedUntilOption.attribute
                  ],
                  /* :: */[
                    /* tuple */[
                      "jiraFieldKey",
                      resolvedUntilOption.jiraFieldKey
                    ],
                    /* :: */[
                      /* tuple */[
                        "regex",
                        resolvedUntilOption.regex
                      ],
                      /* :: */[
                        /* tuple */[
                          "conditions",
                          toJson(conditions)
                        ],
                        /* [] */0
                      ]
                    ]
                  ]
                ]);
    } else if (resolvedUntilOption.attribute !== "" && resolvedUntilOption.jiraFieldKey !== "") {
      return Json_encode.object_(/* :: */[
                  /* tuple */[
                    "attribute",
                    resolvedUntilOption.attribute
                  ],
                  /* :: */[
                    /* tuple */[
                      "jiraFieldKey",
                      resolvedUntilOption.jiraFieldKey
                    ],
                    /* :: */[
                      /* tuple */[
                        "regex",
                        resolvedUntilOption.regex
                      ],
                      /* [] */0
                    ]
                  ]
                ]);
    } else {
      return Json_encode.object_(/* [] */0);
    }
  };
  return Json_encode.array(encodeResolvedUntilOption, resolvedUntilOptions);
}

var ResolvedUntilOptions = {
  Conditions: Conditions,
  fromJson: fromJson$1,
  toJson: toJson$1
};

function fromJson$2(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          description: Json_decode.optional((function (param) {
                  return Json_decode.field("description", Json_decode.string, param);
                }), json)
        };
}

var Component = {
  fromJson: fromJson$2
};

function fromJson$3(json) {
  var match = Js_json.classify(json);
  if (typeof match !== "number") {
    if (match.tag || match[0] !== "manual") {
      return ;
    } else {
      return /* Manual */1;
    }
  }
  switch (match) {
    case /* JSONFalse */0 :
        return /* Disabled */2;
    case /* JSONTrue */1 :
        return /* Enabled */0;
    default:
      return ;
  }
}

function toJson$2(t) {
  switch (t) {
    case /* Enabled */0 :
        return true;
    case /* Manual */1 :
        return "manual";
    case /* Disabled */2 :
        return false;
    
  }
}

function toString(t) {
  switch (t) {
    case /* Enabled */0 :
        return "enabled";
    case /* Manual */1 :
        return "manual";
    case /* Disabled */2 :
        return "disabled";
    
  }
}

function fromString(str) {
  switch (str) {
    case "disabled" :
        return /* Disabled */2;
    case "enabled" :
        return /* Enabled */0;
    case "manual" :
        return /* Manual */1;
    default:
      return ;
  }
}

var JiraToBtSyncOption = {
  fromJson: fromJson$3,
  toJson: toJson$2,
  toString: toString,
  fromString: fromString
};

function fromJson$4(json) {
  return {
          open_: Json_decode.optional((function (param) {
                  return Json_decode.field("open", (function (param) {
                                return Json_decode.array(Json_decode.string, param);
                              }), param);
                }), json),
          inProgress: Json_decode.optional((function (param) {
                  return Json_decode.field("in-progress", (function (param) {
                                return Json_decode.array(Json_decode.string, param);
                              }), param);
                }), json),
          resolved: Json_decode.optional((function (param) {
                  return Json_decode.field("resolved", (function (param) {
                                return Json_decode.array(Json_decode.string, param);
                              }), param);
                }), json),
          muted: Json_decode.optional((function (param) {
                  return Json_decode.field("muted", (function (param) {
                                return Json_decode.array(Json_decode.string, param);
                              }), param);
                }), json),
          reopened: Json_decode.optional((function (param) {
                  return Json_decode.field("reopened", (function (param) {
                                return Json_decode.array(Json_decode.string, param);
                              }), param);
                }), json)
        };
}

var ProjectStatusIds = {
  fromJson: fromJson$4
};

function typeToString(param) {
  switch (param) {
    case /* Open */0 :
        return "Open";
    case /* Resolved */1 :
        return "Resolved";
    case /* InProgress */2 :
        return "In Progress";
    case /* Muted */3 :
        return "Muted";
    case /* Reopened */4 :
        return "Reopened";
    
  }
}

var BacktraceStatuses = {
  typeToString: typeToString
};

function fromJson$5(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json)
        };
}

var StatusCategory = {
  fromJson: fromJson$5
};

function fromJson$6(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          description: Json_decode.field("description", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          untranslatedName: Json_decode.field("untranslatedName", Json_decode.string, json),
          statusCategory: Json_decode.field("statusCategory", fromJson$5, json)
        };
}

var JiraStatuses = {
  StatusCategory: StatusCategory,
  fromJson: fromJson$6
};

function encodeJson(jiraStatusMappingSelections) {
  var selectedStatues = Belt_Option.getWithDefault(jiraStatusMappingSelections, {
        open_: undefined,
        inProgress: undefined,
        resolved: undefined,
        muted: undefined,
        reopened: undefined
      });
  return Json_encode.object_(Belt_List.map(/* :: */[
                  /* tuple */[
                    "open",
                    selectedStatues.open_
                  ],
                  /* :: */[
                    /* tuple */[
                      "resolved",
                      selectedStatues.resolved
                    ],
                    /* :: */[
                      /* tuple */[
                        "in-progress",
                        selectedStatues.inProgress
                      ],
                      /* :: */[
                        /* tuple */[
                          "muted",
                          selectedStatues.muted
                        ],
                        /* :: */[
                          /* tuple */[
                            "reopened",
                            selectedStatues.reopened
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ], (function (param) {
                    var v = param[1];
                    var n = param[0];
                    if (v !== undefined) {
                      return /* tuple */[
                              n,
                              v
                            ];
                    } else {
                      return /* tuple */[
                              n,
                              []
                            ];
                    }
                  })));
}

var Statuses = {
  ProjectStatusIds: ProjectStatusIds,
  BacktraceStatuses: BacktraceStatuses,
  JiraStatuses: JiraStatuses,
  encodeJson: encodeJson
};

function fromJson$7(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          key: Json_decode.field("key", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json)
        };
}

var Project = {
  fromJson: fromJson$7
};

function fromJson$8(json) {
  return {
          body: Json_decode.field("body", (function (param) {
                  return Json_decode.array(fromJson$7, param);
                }), json),
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var Success = {
  Project: Project,
  fromJson: fromJson$8
};

function queryProjectsTask(connectionId, config, projectName, pluginId) {
  var universe = config.universe.name;
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/plugins/" + (pluginId + ("/universes/" + (universe + ("/connections/" + (String(connectionId) + "/data/projects?limit=9999"))))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Get */0, [/* tuple */[
                    "X-Coroner-Project",
                    projectName
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$8(json);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfIntegrations/Jira/QueryProjects.queryProjectsTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var QueryProjects = {
  Success: Success,
  queryProjectsTask: queryProjectsTask
};

function fromJson$9(json) {
  return {
          type_: Json_decode.field("type", Json_decode.string, json),
          items: Json_decode.optional((function (param) {
                  return Json_decode.field("items", Json_decode.string, param);
                }), json)
        };
}

var Schema = {
  fromJson: fromJson$9
};

function fromJson$10(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          schema: Json_decode.field("schema", fromJson$9, json)
        };
}

var AvailableFields = {
  Schema: Schema,
  fromJson: fromJson$10
};

function fromJson$11(json) {
  var list = Belt_List.fromArray(Js_dict.entries(Json_decode.dict(Util$BsConsole.identity, json)));
  return Belt_List.map(list, (function (tup) {
                var k = tup[0];
                var v = Json_decode.string(tup[1]);
                return /* tuple */[
                        k,
                        v
                      ];
              }));
}

var FieldValues = {
  fromJson: fromJson$11
};

function fromJson$12(json) {
  return {
          body: Json_decode.field("body", (function (param) {
                  return Json_decode.array(fromJson$10, param);
                }), json),
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var Success$1 = {
  FieldValues: FieldValues,
  fromJson: fromJson$12
};

function queryFieldValuesTask(connectionId, jiraProjectId, issueTypeId, pluginId, universe, config) {
  var jsonObj = Json_encode.object_(/* :: */[
        /* tuple */[
          "projectId",
          jiraProjectId
        ],
        /* :: */[
          /* tuple */[
            "issueTypeId",
            issueTypeId
          ],
          /* [] */0
        ]
      ]);
  var encodedInstance = btoa(JSON.stringify(jsonObj));
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/plugins/" + (pluginId + ("/universes/" + (universe + ("/connections/" + (String(connectionId) + ("/data/fields?query=" + encodedInstance)))))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Get */0, undefined, (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$12(json);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfIntegrations/Jira/QueryFieldValues.queryFieldValuesTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

function queryCustomFieldValuesTask(connectionId, jiraProjectId, issueTypeId, pluginId, universe, config) {
  var jsonObj = Json_encode.object_(/* :: */[
        /* tuple */[
          "projectId",
          jiraProjectId
        ],
        /* :: */[
          /* tuple */[
            "issueTypeId",
            issueTypeId
          ],
          /* :: */[
            /* tuple */[
              "custom",
              true
            ],
            /* [] */0
          ]
        ]
      ]);
  var encodedInstance = btoa(JSON.stringify(jsonObj));
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/plugins/" + (pluginId + ("/universes/" + (universe + ("/connections/" + (String(connectionId) + ("/data/fields?query=" + encodedInstance)))))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Get */0, undefined, (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$12(json);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfIntegrations/Jira/QueryFieldValues.queryCustomFieldValuesTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var QueryFieldValues = {
  AvailableFields: AvailableFields,
  Success: Success$1,
  queryFieldValuesTask: queryFieldValuesTask,
  queryCustomFieldValuesTask: queryCustomFieldValuesTask
};

function fromJson$13(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          description: Json_decode.field("description", Json_decode.string, json),
          iconUrl: Json_decode.field("iconUrl", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          untranslatedName: Json_decode.optional((function (param) {
                  return Json_decode.field("untranslatedName", Json_decode.string, param);
                }), json)
        };
}

var Issue = {
  fromJson: fromJson$13
};

function fromJson$14(json) {
  return {
          body: Json_decode.field("body", (function (param) {
                  return Json_decode.array(fromJson$13, param);
                }), json),
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var Success$2 = {
  Issue: Issue,
  fromJson: fromJson$14
};

function queryIssuesTask(connectionId, jiraProjectId, config, projectName, pluginId) {
  var jsonObj = Json_encode.object_(/* :: */[
        /* tuple */[
          "projectId",
          jiraProjectId
        ],
        /* [] */0
      ]);
  var encodedInstance = btoa(JSON.stringify(jsonObj));
  var universe = config.universe.name;
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/plugins/" + (pluginId + ("/universes/" + (universe + ("/connections/" + (String(connectionId) + ("/data/issueTypes?query=" + encodedInstance)))))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Get */0, [/* tuple */[
                    "X-Coroner-Project",
                    projectName
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$14(json);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfIntegrations/Jira/QueryIssueTypes.queryIssuesTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var QueryIssueTypes = {
  Success: Success$2,
  queryIssuesTask: queryIssuesTask
};

function fromJson$15(json) {
  return {
          type_: Json_decode.field("type", Json_decode.string, json)
        };
}

var Schema$1 = {
  fromJson: fromJson$15
};

function fromJson$16(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          schema: Json_decode.field("schema", fromJson$15, json)
        };
}

var ContentKey = {
  fromJson: fromJson$16
};

function fromJson$17(json) {
  return {
          body: Json_decode.field("body", (function (param) {
                  return Json_decode.array(fromJson$16, param);
                }), json),
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var Success$3 = {
  Schema: Schema$1,
  ContentKey: ContentKey,
  fromJson: fromJson$17
};

function queryContentKeyTask(connectionId, pluginId, issueTypeId, jiraProjectId, config) {
  var jsonObj = Json_encode.object_(/* :: */[
        /* tuple */[
          "projectId",
          jiraProjectId
        ],
        /* :: */[
          /* tuple */[
            "issueTypeId",
            issueTypeId
          ],
          /* [] */0
        ]
      ]);
  var encodedInstance = btoa(JSON.stringify(jsonObj));
  var universe = config.universe.name;
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/plugins/" + (pluginId + ("/universes/" + (universe + ("/connections/" + (String(connectionId) + ("/data/fields/?query=" + encodedInstance)))))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Get */0, undefined, (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$17(json);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfIntegrations/Jira/QueryContentKey.queryContentKeyTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var QueryContentKeys = {
  Success: Success$3,
  queryContentKeyTask: queryContentKeyTask
};

function fromJson$18(json) {
  return {
          body: Json_decode.field("body", (function (param) {
                  return Json_decode.array(fromJson$2, param);
                }), json),
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var Success$4 = {
  fromJson: fromJson$18
};

function queryComponentTask(connectionId, jiraProjectId, config, projectName, pluginId) {
  var jsonObj = Json_encode.object_(/* :: */[
        /* tuple */[
          "projectId",
          jiraProjectId
        ],
        /* [] */0
      ]);
  var encodedInstance = btoa(JSON.stringify(jsonObj));
  var universe = config.universe.name;
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/plugins/" + (pluginId + ("/universes/" + (universe + ("/connections/" + (String(connectionId) + ("/data/components?query=" + encodedInstance)))))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Get */0, [/* tuple */[
                    "X-Coroner-Project",
                    projectName
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$18(json);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfIntegrations/Jira/QueryComponents.queryComponentsTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var QueryComponents = {
  Success: Success$4,
  queryComponentTask: queryComponentTask
};

function fromJson$19(json) {
  return {
          body: Json_decode.field("body", (function (param) {
                  return Json_decode.array(fromJson$6, param);
                }), json),
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var Success$5 = {
  fromJson: fromJson$19
};

function queryStatusesTask(connectionId, issueTypeId, jiraProjectId, config, projectName, pluginId) {
  var jsonObj = Json_encode.object_(/* :: */[
        /* tuple */[
          "issueTypeId",
          issueTypeId
        ],
        /* :: */[
          /* tuple */[
            "projectId",
            jiraProjectId
          ],
          /* [] */0
        ]
      ]);
  var encodedInstance = btoa(JSON.stringify(jsonObj));
  var universe = config.universe.name;
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/plugins/" + (pluginId + ("/universes/" + (universe + ("/connections/" + (String(connectionId) + ("/data/statuses?query=" + encodedInstance)))))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Get */0, [/* tuple */[
                    "X-Coroner-Project",
                    projectName
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$19(json);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfIntegrations/Jira/QueryStatuses.queryStatusesTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var QueryStatuses = {
  Success: Success$5,
  queryStatusesTask: queryStatusesTask
};

function fromJson$20(json) {
  return {
          assignee: Json_decode.field("assignee", Json_decode.bool, json),
          verifyReopen: Json_decode.field("verifyReopen", Json_decode.bool, json),
          allowedStatuses: Json_decode.field("allowedStatuses", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json)
        };
}

var BacktraceSynchronizationOptions = {
  fromJson: fromJson$20
};

function fromJson$21(json) {
  return {
          attributeList: Json_decode.field("attributeList", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json)
        };
}

var DisplaySettings = {
  fromJson: fromJson$21
};

function fromJson$22(json) {
  return {
          projectId: Json_decode.optional((function (param) {
                  return Json_decode.field("projectId", Json_decode.string, param);
                }), json),
          issueTypeId: Json_decode.optional((function (param) {
                  return Json_decode.field("issueTypeId", Json_decode.string, param);
                }), json),
          contentKey: Json_decode.optional((function (param) {
                  return Json_decode.field("contentKey", Json_decode.string, param);
                }), json),
          summaryTemplate: Json_decode.optional((function (param) {
                  return Json_decode.field("summaryTemplate", Json_decode.string, param);
                }), json),
          projectStatusIds: Json_decode.optional((function (param) {
                  return Json_decode.field("projectStatusIds", fromJson$4, param);
                }), json),
          displaySettings: Json_decode.optional((function (param) {
                  return Json_decode.field("displaySettings", fromJson$21, param);
                }), json),
          backtraceSynchronizationOptions: Json_decode.optional((function (param) {
                  return Json_decode.field("backtraceSynchronizationOptions", fromJson$20, param);
                }), json),
          viewAddress: Json_decode.optional((function (param) {
                  return Json_decode.field("viewAddress", Json_decode.string, param);
                }), json),
          backtraceSync: Json_decode.optional((function (param) {
                  return Json_decode.field("backtraceSync", Json_decode.bool, param);
                }), json)
        };
}

var Defaults = {
  fromJson: fromJson$22
};

function fromJson$23(json) {
  return {
          body: Json_decode.field("body", fromJson$22, json),
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var $$Response = {
  BacktraceSynchronizationOptions: BacktraceSynchronizationOptions,
  DisplaySettings: DisplaySettings,
  Defaults: Defaults,
  fromJson: fromJson$23
};

function queryIntegrationDefaultsTask(connectionId, config, projectName, pluginId, jiraProjectId) {
  var universe = config.universe.name;
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/plugins/" + (pluginId + ("/universes/" + (universe + ("/connections/" + (String(connectionId) + "/data/integration-options-defaults/get"))))));
  var body = Json_encode.object_(/* :: */[
        /* tuple */[
          "projectId",
          jiraProjectId
        ],
        /* [] */0
      ]);
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Post */Block.__(0, [body]), [/* tuple */[
                    "X-Coroner-Project",
                    projectName
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$23(json);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfIntegrations/Jira/QueryIntegrationDefaults.queryIntegrationDefaultsTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var QueryIntegrationDefaults = {
  $$Response: $$Response,
  queryIntegrationDefaultsTask: queryIntegrationDefaultsTask
};

var Jira = {
  JiraType: JiraType,
  ResolvedUntilOptions: ResolvedUntilOptions,
  Component: Component,
  JiraToBtSyncOption: JiraToBtSyncOption,
  Statuses: Statuses,
  QueryProjects: QueryProjects,
  QueryFieldValues: QueryFieldValues,
  QueryIssueTypes: QueryIssueTypes,
  QueryContentKeys: QueryContentKeys,
  QueryComponents: QueryComponents,
  QueryStatuses: QueryStatuses,
  QueryIntegrationDefaults: QueryIntegrationDefaults
};

function fromJson$24(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          type_: Json_decode.field("type", Json_decode.string, json)
        };
}

var Conversations = {
  fromJson: fromJson$24
};

function fromJson$25(json) {
  return {
          body: Json_decode.field("body", (function (param) {
                  return Json_decode.array(fromJson$24, param);
                }), json),
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var Success$6 = {
  Conversations: Conversations,
  fromJson: fromJson$25
};

function queryConversationsTask(connectionId, config, projectName, pluginId) {
  var universe = config.universe.name;
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/plugins/" + (pluginId + ("/universes/" + (universe + ("/connections/" + (String(connectionId) + "/data/conversations"))))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Get */0, [/* tuple */[
                    "X-Coroner-Project",
                    projectName
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$25(json);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfIntegrations/Slack/QueryConversations.queryConversationsTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var QueryConversations = {
  Success: Success$6,
  queryConversationsTask: queryConversationsTask
};

var Slack = {
  QueryConversations: QueryConversations
};

function fromJson$26(json) {
  return {
          successes: Json_decode.field("successes", Json_decode.$$int, json),
          failures: Json_decode.field("failures", Json_decode.$$int, json)
        };
}

var Stats = {
  fromJson: fromJson$26
};

function fromJson$27(json) {
  return {
          creationDate: Json_decode.field("creationDate", Json_decode.string, json),
          updateDate: Json_decode.field("updateDate", Json_decode.string, json),
          lastModifierUserId: Json_decode.field("lastModifierUserId", Json_decode.$$int, json),
          alertIds: Json_decode.field("alertIds", (function (param) {
                  return Json_decode.array(Json_decode.$$int, param);
                }), json),
          stats: Json_decode.optional((function (param) {
                  return Json_decode.field("stats", fromJson$26, param);
                }), json)
        };
}

var Metadata = {
  Stats: Stats,
  fromJson: fromJson$27
};

function fromJson$28(json) {
  return {
          connectionId: Json_decode.field("id", Json_decode.$$int, json)
        };
}

var Connection = {
  fromJson: fromJson$28
};

function fromJson$29(json) {
  return {
          showAttributes: Json_decode.field("showAttributes", Json_decode.bool, json),
          attributeList: Json_decode.field("attributeList", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json),
          includeHostname: Json_decode.optional((function (param) {
                  return Json_decode.field("includeHostname", Json_decode.bool, param);
                }), json),
          showCallstack: Json_decode.optional((function (param) {
                  return Json_decode.field("showCallstack", Json_decode.bool, param);
                }), json),
          showClassifiers: Json_decode.optional((function (param) {
                  return Json_decode.field("showClassifiers", Json_decode.bool, param);
                }), json)
        };
}

var DisplaySettings$1 = {
  fromJson: fromJson$29
};

function fromJson$30(json) {
  return {
          projectId: Json_decode.optional((function (param) {
                  return Json_decode.field("projectId", Json_decode.string, param);
                }), json),
          instanceId: Json_decode.optional((function (param) {
                  return Json_decode.field("instanceId", Json_decode.$$int, param);
                }), json),
          issueTypeId: Json_decode.optional((function (param) {
                  return Json_decode.field("issueTypeId", Json_decode.string, param);
                }), json),
          contentKey: Json_decode.optional((function (param) {
                  return Json_decode.field("contentKey", Json_decode.string, param);
                }), json),
          backtraceSync: Belt_Option.flatMap(Json_decode.optional((function (param) {
                      return Json_decode.field("backtraceSync", fromJson$3, param);
                    }), json), (function (x) {
                  return x;
                })),
          displaySettings: Json_decode.optional((function (param) {
                  return Json_decode.field("displaySettings", fromJson$29, param);
                }), json),
          summaryTemplate: Json_decode.optional((function (param) {
                  return Json_decode.field("summaryTemplate", Json_decode.string, param);
                }), json),
          customFieldValues: Json_decode.optional((function (param) {
                  return Json_decode.field("customFieldValues", fromJson$11, param);
                }), json),
          resolvedUntilOptions: Json_decode.optional((function (param) {
                  return Json_decode.field("resolvedUntilOptions", (function (param) {
                                return Json_decode.array(fromJson$1, param);
                              }), param);
                }), json),
          customComponentIds: Json_decode.optional((function (param) {
                  return Json_decode.field("customComponentIds", (function (param) {
                                return Json_decode.array(Json_decode.string, param);
                              }), param);
                }), json),
          receivers: Json_decode.optional((function (param) {
                  return Json_decode.field("receivers", (function (param) {
                                return Json_decode.array(Json_decode.string, param);
                              }), param);
                }), json),
          projectStatusIds: Json_decode.optional((function (param) {
                  return Json_decode.field("projectStatusIds", fromJson$4, param);
                }), json)
        };
}

var Options = {
  fromJson: fromJson$30
};

function fromJson$31(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          universe: Json_decode.field("universe", Json_decode.string, json),
          universeId: Json_decode.field("universeId", Json_decode.$$int, json),
          project: Json_decode.field("project", Json_decode.string, json),
          projectId: Json_decode.field("projectId", Json_decode.$$int, json),
          watcherName: Json_decode.field("watcherName", Json_decode.string, json),
          pluginId: Json_decode.field("pluginId", Json_decode.string, json),
          address: Json_decode.optional((function (param) {
                  return Json_decode.field("address", Json_decode.string, param);
                }), json),
          viewAddress: Json_decode.optional((function (param) {
                  return Json_decode.field("viewAddress", Json_decode.string, param);
                }), json),
          options: Json_decode.optional((function (param) {
                  return Json_decode.field("options", fromJson$30, param);
                }), json),
          synchronizeIssues: Json_decode.optional((function (param) {
                  return Json_decode.field("synchronizeIssues", Json_decode.bool, param);
                }), json),
          synchronizeIssuesOnAdd: Json_decode.optional((function (param) {
                  return Json_decode.field("synchronizeIssuesOnAdd", Json_decode.bool, param);
                }), json),
          state: Json_decode.field("state", Json_decode.string, json),
          connection: Json_decode.optional((function (param) {
                  return Json_decode.field("connection", fromJson$28, param);
                }), json),
          creatorUserId: Json_decode.field("creatorUserId", Json_decode.$$int, json),
          lastSynchronization: Json_decode.optional((function (param) {
                  return Json_decode.field("lastSynchronization", Json_decode.string, param);
                }), json),
          metadata: Json_decode.optional((function (param) {
                  return Json_decode.field("metadata", fromJson$27, param);
                }), json)
        };
}

var Integration = {
  Metadata: Metadata,
  Connection: Connection,
  DisplaySettings: DisplaySettings$1,
  Options: Options,
  fromJson: fromJson$31
};

function fromJson$32(json) {
  return {
          body: Json_decode.field("body", fromJson$31, json),
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var IntegrationSuccess = {
  fromJson: fromJson$32
};

function fromJson$33(json) {
  return {
          body: Json_decode.field("body", fromJson$31, json),
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var Success$7 = {
  fromJson: fromJson$33
};

function queryIntegrationTask(projectName, pluginId, integrationId, config) {
  var universe = config.universe.name;
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/plugins/" + (pluginId + ("/universes/" + (universe + ("/projects/" + (projectName + ("/integrations/" + String(integrationId))))))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Get */0, [/* tuple */[
                    "X-Coroner-Project",
                    projectName
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$33(json);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfIntegrations/QueryIntegration.queryIntegrationTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var QueryIntegration = {
  Success: Success$7,
  queryIntegrationTask: queryIntegrationTask
};

function fromJson$34(json) {
  return {
          body: Json_decode.field("body", (function (param) {
                  return Json_decode.array(fromJson$31, param);
                }), json),
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var Success$8 = {
  fromJson: fromJson$34
};

function queryIntegrationsTask(projectName, pluginId, config) {
  var universe = config.universe.name;
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/plugins/" + (pluginId + ("/universes/" + (universe + "/integrations/"))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Get */0, [/* tuple */[
                    "X-Coroner-Project",
                    projectName
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$34(json);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfIntegrations/QueryIntegrations.queryIntegrationsTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

function queryIntegrationsByUniverseTask(config) {
  var universe = config.universe.name;
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/universes/" + (universe + "/integrations"));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Get */0, undefined, (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$34(json);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfIntegrations/QueryIntegrationsByUniverse.queryIntegrationsTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var QueryIntegrations = {
  Success: Success$8,
  queryIntegrationsTask: queryIntegrationsTask,
  queryIntegrationsByUniverseTask: queryIntegrationsByUniverseTask
};

function fromJson$35(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          integrationId: Json_decode.field("integrationId", Json_decode.$$int, json),
          level: Json_decode.field("level", Json_decode.string, json),
          message: Json_decode.field("message", Json_decode.string, json),
          timestamp: Json_decode.field("timestamp", Json_decode.string, json)
        };
}

var Entries = {
  fromJson: fromJson$35
};

function fromJson$36(json) {
  return {
          entries: Json_decode.field("entries", (function (param) {
                  return Json_decode.array(fromJson$35, param);
                }), json),
          levels: Json_decode.field("levels", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json)
        };
}

var IntegrationLogs = {
  Entries: Entries,
  fromJson: fromJson$36
};

function fromJson$37(json) {
  return {
          body: Json_decode.field("body", fromJson$36, json),
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var Success$9 = {
  fromJson: fromJson$37
};

function queryIntegrationLogsTask(projectName, integrationId, config) {
  var universe = config.universe.name;
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/universes/" + (universe + ("/projects/" + (projectName + ("/integrations/" + (String(integrationId) + "/logs"))))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Get */0, [/* tuple */[
                    "X-Coroner-Project",
                    projectName
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$37(json);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfIntegrations/QueryIntegrationLogs.queryIntegrationLogsTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var QueryIntegrationLogs = {
  Success: Success$9,
  queryIntegrationLogsTask: queryIntegrationLogsTask
};

function createJiraIntegrationTask(connectionId, issueTypeId, integrationName, projectName, jiraProjectId, contentKey, synchronizeIssues, backtraceSync, synchronizeIssuesOnAdd, summaryTemplate, showAttributes, attributeList, config, pluginId, customFieldValues, resolvedUntilOptions, customComponentIds, projectStatusIds) {
  var jsonObj = Json_encode.object_(/* :: */[
        /* tuple */[
          "project",
          projectName
        ],
        /* :: */[
          /* tuple */[
            "watcherName",
            integrationName
          ],
          /* :: */[
            /* tuple */[
              "pluginId",
              pluginId
            ],
            /* :: */[
              /* tuple */[
                "state",
                "enabled"
              ],
              /* :: */[
                /* tuple */[
                  "connectionId",
                  connectionId
                ],
                /* :: */[
                  /* tuple */[
                    "synchronizeIssues",
                    synchronizeIssues
                  ],
                  /* :: */[
                    /* tuple */[
                      "synchronizeIssuesOnAdd",
                      synchronizeIssuesOnAdd
                    ],
                    /* :: */[
                      /* tuple */[
                        "options",
                        Json_encode.object_(Belt_List.concat(/* :: */[
                                  /* tuple */[
                                    "projectId",
                                    jiraProjectId
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "issueTypeId",
                                      issueTypeId
                                    ],
                                    /* :: */[
                                      /* tuple */[
                                        "contentKey",
                                        contentKey
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "backtraceSync",
                                          toJson$2(backtraceSync)
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "displaySettings",
                                            Json_encode.object_(/* :: */[
                                                  /* tuple */[
                                                    "showAttributes",
                                                    showAttributes
                                                  ],
                                                  /* :: */[
                                                    /* tuple */[
                                                      "attributeList",
                                                      attributeList
                                                    ],
                                                    /* [] */0
                                                  ]
                                                ])
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "customFieldValues",
                                              Json_encode.object_(Belt_List.map(customFieldValues, (function (tup) {
                                                          return /* tuple */[
                                                                  tup[0],
                                                                  tup[1]
                                                                ];
                                                        })))
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "resolvedUntilOptions",
                                                toJson$1(resolvedUntilOptions)
                                              ],
                                              /* :: */[
                                                /* tuple */[
                                                  "summaryTemplate",
                                                  summaryTemplate
                                                ],
                                                /* :: */[
                                                  /* tuple */[
                                                    "customComponentIds",
                                                    customComponentIds
                                                  ],
                                                  /* [] */0
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ], projectStatusIds !== undefined ? /* :: */[
                                    /* tuple */[
                                      "projectStatusIds",
                                      Caml_option.valFromOption(projectStatusIds)
                                    ],
                                    /* [] */0
                                  ] : /* [] */0))
                      ],
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]);
  var universe = config.universe.name;
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/plugins/" + (pluginId + ("/universes/" + (universe + "/integrations/"))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Post */Block.__(0, [jsonObj]), [/* tuple */[
                    "X-Coroner-Project",
                    projectName
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$32(json);
                      Task2$BsConsole.invalidateCache(ApiWf$BsConsole.cacheKey);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfIntegrations/CreateIntegration.createJiraIntegrationTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

function createSlackIntegrationTask(pluginId, projectName, config, watcherName, connectionId, receivers, showAttributes, attributeList) {
  var universe = config.universe.name;
  var jsonObj = Json_encode.object_(/* :: */[
        /* tuple */[
          "watcherName",
          watcherName
        ],
        /* :: */[
          /* tuple */[
            "connectionId",
            connectionId
          ],
          /* :: */[
            /* tuple */[
              "pluginId",
              pluginId
            ],
            /* :: */[
              /* tuple */[
                "project",
                projectName
              ],
              /* :: */[
                /* tuple */[
                  "universe",
                  universe
                ],
                /* :: */[
                  /* tuple */[
                    "options",
                    Json_encode.object_(/* :: */[
                          /* tuple */[
                            "receivers",
                            receivers
                          ],
                          /* :: */[
                            /* tuple */[
                              "displaySettings",
                              Json_encode.object_(/* :: */[
                                    /* tuple */[
                                      "showAttributes",
                                      showAttributes
                                    ],
                                    /* :: */[
                                      /* tuple */[
                                        "attributeList",
                                        attributeList
                                      ],
                                      /* [] */0
                                    ]
                                  ])
                            ],
                            /* [] */0
                          ]
                        ])
                  ],
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]);
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/plugins/" + (pluginId + ("/universes/" + (universe + "/integrations/"))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Post */Block.__(0, [jsonObj]), [/* tuple */[
                    "X-Coroner-Project",
                    projectName
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$32(json);
                      Task2$BsConsole.invalidateCache(ApiWf$BsConsole.cacheKey);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfIntegrations/CreateIntegration.createSlackIntegrationTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var CreateIntegration = {
  createJiraIntegrationTask: createJiraIntegrationTask,
  createSlackIntegrationTask: createSlackIntegrationTask
};

function editJiraIntegrationTask(connectionId, issueTypeId, integrationId, projectName, jiraProjectId, contentKey, synchronizeIssues, backtraceSync, synchronizeIssuesOnAdd, summaryTemplate, showAttributes, attributeList, config, pluginId, customFieldValues, resolvedUntilOptions, customComponentIds, projectStatusIds) {
  var jsonObj = Json_encode.object_(/* :: */[
        /* tuple */[
          "project",
          projectName
        ],
        /* :: */[
          /* tuple */[
            "state",
            "enabled"
          ],
          /* :: */[
            /* tuple */[
              "connectionId",
              connectionId
            ],
            /* :: */[
              /* tuple */[
                "synchronizeIssues",
                synchronizeIssues
              ],
              /* :: */[
                /* tuple */[
                  "synchronizeIssuesOnAdd",
                  synchronizeIssuesOnAdd
                ],
                /* :: */[
                  /* tuple */[
                    "options",
                    Json_encode.object_(Belt_List.concat(/* :: */[
                              /* tuple */[
                                "projectId",
                                jiraProjectId
                              ],
                              /* :: */[
                                /* tuple */[
                                  "issueTypeId",
                                  issueTypeId
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "contentKey",
                                    contentKey
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "backtraceSync",
                                      toJson$2(backtraceSync)
                                    ],
                                    /* :: */[
                                      /* tuple */[
                                        "displaySettings",
                                        Json_encode.object_(/* :: */[
                                              /* tuple */[
                                                "showAttributes",
                                                showAttributes
                                              ],
                                              /* :: */[
                                                /* tuple */[
                                                  "attributeList",
                                                  attributeList
                                                ],
                                                /* [] */0
                                              ]
                                            ])
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "customFieldValues",
                                          Json_encode.object_(Belt_List.map(customFieldValues, (function (tup) {
                                                      return /* tuple */[
                                                              tup[0],
                                                              tup[1]
                                                            ];
                                                    })))
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "resolvedUntilOptions",
                                            toJson$1(resolvedUntilOptions)
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "summaryTemplate",
                                              summaryTemplate
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "customComponentIds",
                                                customComponentIds
                                              ],
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ], projectStatusIds !== undefined ? /* :: */[
                                /* tuple */[
                                  "projectStatusIds",
                                  Caml_option.valFromOption(projectStatusIds)
                                ],
                                /* [] */0
                              ] : /* [] */0))
                  ],
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]);
  var universe = config.universe.name;
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/plugins/" + (pluginId + ("/universes/" + (universe + ("/projects/" + (projectName + ("/integrations/" + String(integrationId))))))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Put */Block.__(1, [jsonObj]), [/* tuple */[
                    "X-Coroner-Project",
                    projectName
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$32(json);
                      Task2$BsConsole.invalidateCache(ApiWf$BsConsole.cacheKey);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfIntegrations/EditIntegration.editJiraIntegrationTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

function editSlackIntegrationTask(pluginId, projectName, config, integrationId, watcherName, connectionId, receivers, showAttributes, attributeList) {
  var jsonObj = Json_encode.object_(/* :: */[
        /* tuple */[
          "watcherName",
          watcherName
        ],
        /* :: */[
          /* tuple */[
            "connectionId",
            connectionId
          ],
          /* :: */[
            /* tuple */[
              "options",
              Json_encode.object_(/* :: */[
                    /* tuple */[
                      "receivers",
                      receivers
                    ],
                    /* :: */[
                      /* tuple */[
                        "displaySettings",
                        Json_encode.object_(/* :: */[
                              /* tuple */[
                                "showAttributes",
                                showAttributes
                              ],
                              /* :: */[
                                /* tuple */[
                                  "attributeList",
                                  attributeList
                                ],
                                /* [] */0
                              ]
                            ])
                      ],
                      /* [] */0
                    ]
                  ])
            ],
            /* [] */0
          ]
        ]
      ]);
  var universe = config.universe.name;
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/plugins/" + (pluginId + ("/universes/" + (universe + ("/projects/" + (projectName + ("/integrations/" + String(integrationId))))))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Put */Block.__(1, [jsonObj]), [/* tuple */[
                    "X-Coroner-Project",
                    projectName
                  ]], (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$32(json);
                      Task2$BsConsole.invalidateCache(ApiWf$BsConsole.cacheKey);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfIntegrations/EditIntegration.editJiraIntegrationTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var EditIntegration = {
  editJiraIntegrationTask: editJiraIntegrationTask,
  editSlackIntegrationTask: editSlackIntegrationTask
};

function transferIntegrationTask(config, integrationId, connectionId) {
  var jsonObj = Json_encode.object_(/* :: */[
        /* tuple */[
          "connectionId",
          connectionId
        ],
        /* [] */0
      ]);
  var universe = config.universe.name;
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/universes/" + (universe + ("/integrations/" + String(integrationId))));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Put */Block.__(1, [jsonObj]), undefined, (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$32(json);
                      Task2$BsConsole.invalidateCache(ApiWf$BsConsole.cacheKey);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfIntegrations/TransferIntegration.transferIntegrationTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var TransferIntegration = {
  transferIntegrationTask: transferIntegrationTask
};

var $$default = {
  error: "N/A",
  error_description: "N/A"
};

function fromJson$38(json) {
  return {
          error: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("error", Json_decode.string, param);
                    }), json), "N/A"),
          error_description: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("error_description", Json_decode.string, param);
                    }), json), "N/A")
        };
}

var ErrorData = {
  $$default: $$default,
  fromJson: fromJson$38
};

function fromJson$39(json) {
  var jsonString = Json_decode.optional((function (param) {
          return Json_decode.field("error", Json_decode.string, param);
        }), json);
  var tmp;
  if (jsonString !== undefined) {
    try {
      tmp = fromJson$38(JSON.parse(jsonString));
    }
    catch (exn){
      tmp = $$default;
    }
  } else {
    tmp = $$default;
  }
  return {
          error: tmp,
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var Result = {
  ErrorData: ErrorData,
  fromJson: fromJson$39
};

function fromJson$40(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          result: Json_decode.field("result", fromJson$39, json)
        };
}

var Failed = {
  Result: Result,
  fromJson: fromJson$40
};

var BulkActions = {
  Failed: Failed
};

function fromJson$41(json) {
  return {
          deleted: Json_decode.field("updated", (function (param) {
                  return Json_decode.array(fromJson$31, param);
                }), json),
          failed: Json_decode.field("failed", (function (param) {
                  return Json_decode.array(fromJson$40, param);
                }), json)
        };
}

var TransferBody = {
  fromJson: fromJson$41
};

function fromJson$42(json) {
  return {
          body: Json_decode.field("body", fromJson$41, json),
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var Success$10 = {
  TransferBody: TransferBody,
  fromJson: fromJson$42
};

function bulkTransferIntegrationsTask(config, integrationIds, newConnectionId) {
  var encodeIntegration = function (integrationId) {
    return Json_encode.object_(/* :: */[
                /* tuple */[
                  "id",
                  integrationId
                ],
                /* :: */[
                  /* tuple */[
                    "connectionId",
                    newConnectionId
                  ],
                  /* [] */0
                ]
              ]);
  };
  var jsonObj = Json_encode.object_(/* :: */[
        /* tuple */[
          "integrations",
          Json_encode.array(encodeIntegration, integrationIds)
        ],
        /* [] */0
      ]);
  var universe = config.universe.name;
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/universes/" + (universe + "/integrations/_bulk"));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Put */Block.__(1, [jsonObj]), undefined, (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$42(json);
                      Task2$BsConsole.invalidateCache(ApiWf$BsConsole.cacheKey);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfIntegrations/BulkTransferIntegrations.bulkTransferIntegrationsTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var BulkTransferIntegrations = {
  Success: Success$10,
  bulkTransferIntegrationsTask: bulkTransferIntegrationsTask
};

function fromJson$43(json) {
  return {
          deleted: Json_decode.field("deleted", (function (param) {
                  return Json_decode.array(fromJson$31, param);
                }), json),
          failed: Json_decode.field("failed", (function (param) {
                  return Json_decode.array(fromJson$40, param);
                }), json)
        };
}

var DeletionBody = {
  fromJson: fromJson$43
};

function fromJson$44(json) {
  return {
          body: Json_decode.field("body", fromJson$43, json),
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var Success$11 = {
  DeletionBody: DeletionBody,
  fromJson: fromJson$44
};

function bulkDeleteIntegrationsTask(config, integrationIds) {
  var jsonObj = Json_encode.object_(/* :: */[
        /* tuple */[
          "integrationIds",
          integrationIds
        ],
        /* [] */0
      ]);
  var universe = config.universe.name;
  var baseUrl = WorkflowVersion$BsConsole.current(config);
  if (typeof baseUrl === "number") {
    return ;
  }
  var url = baseUrl[0] + ("/universes/" + (universe + "/integrations/_bulk"));
  return Caml_option.some(Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* DeleteBody */Block.__(2, [jsonObj]), undefined, (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = ApiWf$BsConsole.$$Error.fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ApiWf$BsConsole.ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$44(json);
                      Task2$BsConsole.invalidateCache(ApiWf$BsConsole.cacheKey);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "ApiWfIntegrations/BulkDeleteIntegrations.bulkDeleteIntegrationsTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ApiWf$BsConsole.ParseError,
                                  error
                                ]]);
                    }
                  }), undefined, undefined));
}

var BulkDeleteIntegrations = {
  Success: Success$11,
  bulkDeleteIntegrationsTask: bulkDeleteIntegrationsTask
};

exports.Jira = Jira;
exports.Slack = Slack;
exports.Integration = Integration;
exports.IntegrationSuccess = IntegrationSuccess;
exports.QueryIntegration = QueryIntegration;
exports.QueryIntegrations = QueryIntegrations;
exports.IntegrationLogs = IntegrationLogs;
exports.QueryIntegrationLogs = QueryIntegrationLogs;
exports.CreateIntegration = CreateIntegration;
exports.EditIntegration = EditIntegration;
exports.TransferIntegration = TransferIntegration;
exports.BulkActions = BulkActions;
exports.BulkTransferIntegrations = BulkTransferIntegrations;
exports.BulkDeleteIntegrations = BulkDeleteIntegrations;
/* Util-BsConsole Not a pure module */
