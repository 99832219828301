// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json = require("@glennsl/bs-json/lib/js/src/Json.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Belt_Result = require("bs-platform/lib/js/belt_Result.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Util$BsConsole = require("./util.js");
var Task2$BsConsole = require("./Task2.js");
var BpgTask$BsConsole = require("./BpgTask.js");
var ReopenTime$BsConsole = require("./reopen/ReopenTime.js");
var DefaultView$BsConsole = require("./saved-queries/DefaultView.js");
var CoronerdGate$BsConsole = require("./CoronerdGate.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");
var BugHighlightBarDefaults$BsConsole = require("./debugger2/BugHighlightBarDefaults.js");

function ofJson(json) {
  return {
          hasSeenFirstTimeModal: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("has_seen_first_time_modal", Json_decode.bool, param);
                    }), json), false)
        };
}

function toJson(t) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "has_seen_first_time_modal",
                t.hasSeenFirstTimeModal
              ],
              /* [] */0
            ]);
}

var UnityCDA = {
  ofJson: ofJson,
  toJson: toJson
};

var $$default = {
  bookmarksVersion: 0
};

function ofJson$1(json) {
  return {
          bookmarksVersion: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("bookmarksVersion", Json_decode.$$int, param);
                    }), json), 0)
        };
}

function toJson$1(t) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "bookmarksVersion",
                t.bookmarksVersion
              ],
              /* [] */0
            ]);
}

var FirstTimeExperience = {
  $$default: $$default,
  ofJson: ofJson$1,
  toJson: toJson$1
};

var $$default$1 = {
  condenseFunctions: true,
  showVariables: false,
  showBugTabsDataRaw: false
};

function ofJson$2(json) {
  return {
          condenseFunctions: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("condenseFunctions", Json_decode.bool, param);
                    }), json), true),
          showVariables: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("showVariables", Json_decode.bool, param);
                    }), json), false),
          showBugTabsDataRaw: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("showBugTabsDataRaw", Json_decode.bool, param);
                    }), json), false)
        };
}

function toJson$2(t) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "condenseFunctions",
                t.condenseFunctions
              ],
              /* :: */[
                /* tuple */[
                  "showVariables",
                  t.showVariables
                ],
                /* :: */[
                  /* tuple */[
                    "showBugTabsDataRaw",
                    t.showBugTabsDataRaw
                  ],
                  /* [] */0
                ]
              ]
            ]);
}

var DebuggerToggles = {
  $$default: $$default$1,
  ofJson: ofJson$2,
  toJson: toJson$2
};

var empty_bookmarkedViews = { };

var empty_firstTimeExperience = { };

var empty_highlightbarAttributes = { };

var empty_debugViewToggles = $$default$1;

var empty = {
  projectDefaultViews: undefined,
  bookmarkedViews: empty_bookmarkedViews,
  reopenLatestSelectedAttribute: undefined,
  reopenLatestSelectedType: undefined,
  reopenLatestAttributeValues: undefined,
  reopenLatestSelectedTimePresent: undefined,
  unityCDA: undefined,
  firstTimeExperience: empty_firstTimeExperience,
  highlightbarAttributes: empty_highlightbarAttributes,
  debugViewToggles: empty_debugViewToggles,
  languageCode: undefined,
  fingerprintDetailsAttrs: undefined,
  nlqRecentQuestions: undefined
};

function ofJson$3(json) {
  var projectDefaultViews = Json_decode.optional((function (param) {
          return Json_decode.field("projectDefaultViews", (function (param) {
                        return Json_decode.dict(DefaultView$BsConsole.ofJson, param);
                      }), param);
        }), json);
  var __x = Json_decode.optional((function (param) {
          return Json_decode.field("bookmarkedViews", (function (param) {
                        return Json_decode.dict((function (param) {
                                      return Json_decode.array(Json_decode.string, param);
                                    }), param);
                      }), param);
        }), json);
  var bookmarkedViews = Belt_Option.getWithDefault(__x, { });
  var reopenLatestSelectedAttribute = Json_decode.optional((function (param) {
          return Json_decode.field("reopenLatestSelectedAttribute", Json_decode.string, param);
        }), json);
  var partial_arg_000 = function (param) {
    return Json_decode.field("reopenType", Json_decode.string, param);
  };
  var partial_arg_001 = /* :: */[
    (function (param) {
        return Json_decode.field("reopenLatestSelectedType", Json_decode.string, param);
      }),
    /* [] */0
  ];
  var partial_arg = /* :: */[
    partial_arg_000,
    partial_arg_001
  ];
  var reopenLatestSelectedType = Json_decode.optional((function (param) {
          return Json_decode.oneOf(partial_arg, param);
        }), json);
  var reopenLatestAttributeValues = Json_decode.optional((function (param) {
          return Json_decode.field("reopenLatestAttributeValues", (function (param) {
                        return Json_decode.list((function (param) {
                                      return Json_decode.tuple2(Json_decode.string, Json_decode.string, param);
                                    }), param);
                      }), param);
        }), json);
  var reopenLatestSelectedTimePresent = Json_decode.optional((function (param) {
          return Json_decode.field("reopenLatestSelectedTimePresent", (function (json) {
                        return ReopenTime$BsConsole.TimePresets.fromString(Json_decode.string(json));
                      }), param);
        }), json);
  var unityCDA = Json_decode.optional((function (param) {
          return Json_decode.field("unity_CDA", ofJson, param);
        }), json);
  var __x$1 = Json_decode.optional((function (param) {
          return Json_decode.field("firstTimeExperience", (function (param) {
                        return Json_decode.dict(ofJson$1, param);
                      }), param);
        }), json);
  var firstTimeExperience = Belt_Option.getWithDefault(__x$1, { });
  var __x$2 = Json_decode.optional((function (param) {
          return Json_decode.field("highlightbarAttributes", (function (param) {
                        return Json_decode.dict((function (param) {
                                      return Json_decode.array(Json_decode.string, param);
                                    }), param);
                      }), param);
        }), json);
  var highlightbarAttributes = Belt_Option.getWithDefault(__x$2, { });
  var debugViewToggles = Json_decode.optional((function (param) {
          return Json_decode.field("debugViewToggles", ofJson$2, param);
        }), json);
  var languageCode = Json_decode.optional((function (param) {
          return Json_decode.field("languageCode", Json_decode.string, param);
        }), json);
  var fingerprintDetailsAttrs = Json_decode.optional((function (param) {
          return Json_decode.field("fingerprintDetailsAttrs", (function (param) {
                        return Json_decode.list(Json_decode.string, param);
                      }), param);
        }), json);
  var nlqRecentQuestions = Json_decode.optional((function (param) {
          return Json_decode.field("nlqRecentQuestions", (function (param) {
                        return Json_decode.array(Json_decode.string, param);
                      }), param);
        }), json);
  return {
          projectDefaultViews: projectDefaultViews,
          bookmarkedViews: bookmarkedViews,
          reopenLatestSelectedAttribute: reopenLatestSelectedAttribute,
          reopenLatestSelectedType: reopenLatestSelectedType,
          reopenLatestAttributeValues: reopenLatestAttributeValues,
          reopenLatestSelectedTimePresent: reopenLatestSelectedTimePresent,
          unityCDA: unityCDA,
          firstTimeExperience: firstTimeExperience,
          highlightbarAttributes: highlightbarAttributes,
          debugViewToggles: debugViewToggles,
          languageCode: languageCode,
          fingerprintDetailsAttrs: fingerprintDetailsAttrs,
          nlqRecentQuestions: nlqRecentQuestions
        };
}

function toJson$3(t) {
  var projectDefaultViews = t.projectDefaultViews;
  var tmp;
  if (projectDefaultViews !== undefined) {
    var projectDefaultViews$1 = Caml_option.valFromOption(projectDefaultViews);
    tmp = Json_encode.object_(Belt_List.map(Belt_List.fromArray(Object.keys(projectDefaultViews$1)), (function (proj) {
                return /* tuple */[
                        proj,
                        Belt_Option.mapWithDefault(Js_dict.get(projectDefaultViews$1, proj), "", DefaultView$BsConsole.toJson)
                      ];
              })));
  } else {
    tmp = "";
  }
  var attr = t.reopenLatestSelectedAttribute;
  var reopenLatestSelectedType$prime = t.reopenLatestSelectedType;
  var reopenLatestAttributeValues$prime = t.reopenLatestAttributeValues;
  var reopenLatestSelectedTimePresent$prime = t.reopenLatestSelectedTimePresent;
  var u = t.unityCDA;
  var dVT = t.debugViewToggles;
  var langCode = t.languageCode;
  var fingerprintDetailsAttrs$prime = t.fingerprintDetailsAttrs;
  var nlqRecentQuestions = t.nlqRecentQuestions;
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "projectDefaultViews",
                tmp
              ],
              /* :: */[
                /* tuple */[
                  "bookmarkedViews",
                  Json_encode.object_(Belt_List.map(Belt_List.fromArray(Js_dict.entries(t.bookmarkedViews)), (function (param) {
                              return /* tuple */[
                                      param[0],
                                      param[1]
                                    ];
                            })))
                ],
                /* :: */[
                  /* tuple */[
                    "firstTimeExperience",
                    Json_encode.object_(Belt_List.map(Belt_List.fromArray(Js_dict.entries(t.firstTimeExperience)), (function (param) {
                                return /* tuple */[
                                        param[0],
                                        toJson$1(param[1])
                                      ];
                              })))
                  ],
                  /* :: */[
                    /* tuple */[
                      "reopenLatestSelectedAttribute",
                      attr !== undefined ? attr : null
                    ],
                    /* :: */[
                      /* tuple */[
                        "reopenLatestSelectedType",
                        reopenLatestSelectedType$prime !== undefined ? reopenLatestSelectedType$prime : null
                      ],
                      /* :: */[
                        /* tuple */[
                          "reopenLatestAttributeValues",
                          reopenLatestAttributeValues$prime !== undefined ? Json_encode.list((function (param) {
                                    return Json_encode.tuple2((function (prim) {
                                                  return prim;
                                                }), (function (prim) {
                                                  return prim;
                                                }), param);
                                  }), reopenLatestAttributeValues$prime) : null
                        ],
                        /* :: */[
                          /* tuple */[
                            "reopenLatestSelectedTimePresent",
                            reopenLatestSelectedTimePresent$prime !== undefined ? ReopenTime$BsConsole.TimePresets.toString(reopenLatestSelectedTimePresent$prime) : null
                          ],
                          /* :: */[
                            /* tuple */[
                              "unity_CDA",
                              u !== undefined ? toJson(u) : null
                            ],
                            /* :: */[
                              /* tuple */[
                                "highlightbarAttributes",
                                Json_encode.object_(Belt_List.map(Belt_List.fromArray(Js_dict.entries(t.highlightbarAttributes)), (function (param) {
                                            return /* tuple */[
                                                    param[0],
                                                    param[1]
                                                  ];
                                          })))
                              ],
                              /* :: */[
                                /* tuple */[
                                  "debugViewToggles",
                                  dVT !== undefined ? toJson$2(dVT) : toJson$2($$default$1)
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "languageCode",
                                    langCode !== undefined ? langCode : null
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "fingerprintDetailsAttrs",
                                      fingerprintDetailsAttrs$prime !== undefined ? Json_encode.list((function (prim) {
                                                return prim;
                                              }), fingerprintDetailsAttrs$prime) : null
                                    ],
                                    /* :: */[
                                      /* tuple */[
                                        "nlqRecentQuestions",
                                        nlqRecentQuestions !== undefined ? Json_encode.array((function (prim) {
                                                  return prim;
                                                }), nlqRecentQuestions) : null
                                      ],
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

function toBPG(settings, owner) {
  return {
          owner: owner,
          json: JSON.stringify(toJson$3(settings)),
          __state: undefined,
          __create: undefined,
          __modify: undefined
        };
}

function getUnparsedSettingsFromBpg(token, uid, cb) {
  var arg = BpgTask$BsConsole.UserFrontendSettings.fetchAll;
  Curry._2((function (param) {
            return (function (param$1, param$2) {
                return Task2$BsConsole.handle(param, arg, param$1, param$2);
              });
          })(token), true, (function (allUserSettings) {
          if (allUserSettings.tag) {
            Curry._1(cb, /* Error */Block.__(1, [allUserSettings[0]]));
          } else {
            var myUserSettingsBpg = Belt_Array.getBy(allUserSettings[0], (function (s) {
                    return s.owner === uid;
                  }));
            Curry._1(cb, /* Ok */Block.__(0, [myUserSettingsBpg]));
          }
          
        }));
  
}

function getParsedSettingsFromBpg(token, uid, cb) {
  return getUnparsedSettingsFromBpg(token, uid, (function (userSettingsBpgResult) {
                return Belt_Result.map(userSettingsBpgResult, (function (userSettingsBpg) {
                              if (userSettingsBpg === undefined) {
                                return Curry._1(cb, undefined);
                              }
                              var parsedSettings = Json.parse(userSettingsBpg.json);
                              return Curry._1(cb, Belt_Option.map(parsedSettings, ofJson$3));
                            }));
              }));
}

function deleteUserSettingsForUser(token, uid, successCb, errorCb) {
  return getUnparsedSettingsFromBpg(token, uid, (function (userSettings) {
                if (userSettings.tag) {
                  return Curry._1(errorCb, undefined);
                }
                var userSettings$1 = userSettings[0];
                if (userSettings$1 !== undefined) {
                  return Task2$BsConsole.handle(token, BpgTask$BsConsole.UserFrontendSettings.$$delete(userSettings$1), undefined, (function (_resp) {
                                return Curry._1(successCb, undefined);
                              }));
                } else {
                  return Curry._1(successCb, undefined);
                }
              }));
}

function getBookmarks(projectName, t) {
  return Belt_Option.getWithDefault(Js_dict.get(t.bookmarkedViews, projectName), []);
}

function getFirstTimeUserExperience(projectName, t) {
  return Belt_Option.getWithDefault(Js_dict.get(t.firstTimeExperience, projectName), $$default);
}

function getDefaultView(projectName, t) {
  return Belt_Option.flatMap(t.projectDefaultViews, (function (views) {
                return Js_dict.get(views, projectName);
              }));
}

function updateOrCreate(token, uid, isGuest, newSettingsFn, onSuccess, onFailure) {
  var match = CoronerdGate$BsConsole.isEnoughCoronerdVersion(CoronerdGate$BsConsole.guestUserFrontendSettingsMinimumVersion);
  if (!match && isGuest) {
    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* UserFrontendSettingsGuestBelowCoronerdVersion */135]));
    return Curry._1(onFailure, "Guest tried to create UserFrontendSettings with coronerd version below " + CoronerdGate$BsConsole.guestUserFrontendSettingsMinimumVersion);
  }
  return getParsedSettingsFromBpg(token, uid, (function (frontendSettings) {
                var newSettings = Curry._1(newSettingsFn, frontendSettings);
                if (frontendSettings !== undefined) {
                  var arg = BpgTask$BsConsole.UserFrontendSettings.updateFieldsByKey({
                        owner: uid
                      }, [/* tuple */[
                          "json",
                          toJson$3(newSettings)
                        ]]);
                  Curry._2((function (param) {
                            return (function (param$1, param$2) {
                                return Task2$BsConsole.handle(param, arg, param$1, param$2);
                              });
                          })(token), undefined, (function (res) {
                          if (!res.tag) {
                            return Curry._1(onSuccess, undefined);
                          }
                          var err = res[0];
                          if (err[0] === BpgTask$BsConsole.BpgError) {
                            return Curry._1(onFailure, err[2]);
                          } else {
                            return Curry._1(onFailure, "Unknown UserFrontendSettings error");
                          }
                        }));
                  return ;
                }
                var arg$1 = BpgTask$BsConsole.UserFrontendSettings.create(toBPG(newSettings, uid));
                return Curry._2((function (param) {
                                return (function (param$1, param$2) {
                                    return Task2$BsConsole.handle(param, arg$1, param$1, param$2);
                                  });
                              })(token), true, (function (res) {
                              if (!res.tag) {
                                return Curry._1(onSuccess, undefined);
                              }
                              var err = res[0];
                              if (err[0] === BpgTask$BsConsole.BpgError) {
                                return Curry._1(onFailure, err[2]);
                              } else {
                                return Curry._1(onFailure, "Unknown UserFrontendSettings error");
                              }
                            }));
              }));
}

function getNextSettings(actions, settings) {
  return Belt_List.reduce(actions, settings, (function (accumulator, action) {
                if (typeof action === "number") {
                  return {
                          projectDefaultViews: accumulator.projectDefaultViews,
                          bookmarkedViews: accumulator.bookmarkedViews,
                          reopenLatestSelectedAttribute: accumulator.reopenLatestSelectedAttribute,
                          reopenLatestSelectedType: accumulator.reopenLatestSelectedType,
                          reopenLatestAttributeValues: accumulator.reopenLatestAttributeValues,
                          reopenLatestSelectedTimePresent: accumulator.reopenLatestSelectedTimePresent,
                          unityCDA: accumulator.unityCDA,
                          firstTimeExperience: accumulator.firstTimeExperience,
                          highlightbarAttributes: accumulator.highlightbarAttributes,
                          debugViewToggles: accumulator.debugViewToggles,
                          languageCode: undefined,
                          fingerprintDetailsAttrs: accumulator.fingerprintDetailsAttrs,
                          nlqRecentQuestions: accumulator.nlqRecentQuestions
                        };
                }
                switch (action.tag | 0) {
                  case /* AddBookmarksWithDefaults */0 :
                      var projectName = action[0];
                      var firstTimeExperience = accumulator.firstTimeExperience;
                      Belt_Option.getWithDefault(Js_dict.get(accumulator.firstTimeExperience, projectName), $$default);
                      firstTimeExperience[projectName] = {
                        bookmarksVersion: action[2]
                      };
                      var bookmarkedViews = accumulator.bookmarkedViews;
                      var currentProjectsBookmarkedViews = Belt_Option.getWithDefault(Js_dict.get(accumulator.bookmarkedViews, projectName), []);
                      var nextBookmarkedViews = Belt_Array.reduce(action[1], currentProjectsBookmarkedViews, (function (acc, curr) {
                              if (acc.includes(curr)) {
                                return acc;
                              } else {
                                return Belt_Array.concat(acc, [curr]);
                              }
                            }));
                      bookmarkedViews[projectName] = nextBookmarkedViews;
                      return {
                              projectDefaultViews: accumulator.projectDefaultViews,
                              bookmarkedViews: bookmarkedViews,
                              reopenLatestSelectedAttribute: accumulator.reopenLatestSelectedAttribute,
                              reopenLatestSelectedType: accumulator.reopenLatestSelectedType,
                              reopenLatestAttributeValues: accumulator.reopenLatestAttributeValues,
                              reopenLatestSelectedTimePresent: accumulator.reopenLatestSelectedTimePresent,
                              unityCDA: accumulator.unityCDA,
                              firstTimeExperience: firstTimeExperience,
                              highlightbarAttributes: accumulator.highlightbarAttributes,
                              debugViewToggles: accumulator.debugViewToggles,
                              languageCode: accumulator.languageCode,
                              fingerprintDetailsAttrs: accumulator.fingerprintDetailsAttrs,
                              nlqRecentQuestions: accumulator.nlqRecentQuestions
                            };
                  case /* AddBookmarkedView */1 :
                      var projectName$1 = action[0];
                      var bookmarkedViews$1 = accumulator.bookmarkedViews;
                      var currentProjectsBookmarkedViews$1 = Belt_Option.getWithDefault(Js_dict.get(accumulator.bookmarkedViews, projectName$1), []);
                      var nextBookmarkedViews$1 = Belt_Array.concat(currentProjectsBookmarkedViews$1, [action[1]]);
                      bookmarkedViews$1[projectName$1] = nextBookmarkedViews$1;
                      return {
                              projectDefaultViews: accumulator.projectDefaultViews,
                              bookmarkedViews: bookmarkedViews$1,
                              reopenLatestSelectedAttribute: accumulator.reopenLatestSelectedAttribute,
                              reopenLatestSelectedType: accumulator.reopenLatestSelectedType,
                              reopenLatestAttributeValues: accumulator.reopenLatestAttributeValues,
                              reopenLatestSelectedTimePresent: accumulator.reopenLatestSelectedTimePresent,
                              unityCDA: accumulator.unityCDA,
                              firstTimeExperience: accumulator.firstTimeExperience,
                              highlightbarAttributes: accumulator.highlightbarAttributes,
                              debugViewToggles: accumulator.debugViewToggles,
                              languageCode: accumulator.languageCode,
                              fingerprintDetailsAttrs: accumulator.fingerprintDetailsAttrs,
                              nlqRecentQuestions: accumulator.nlqRecentQuestions
                            };
                  case /* RemoveBookmarkedView */2 :
                      var view = action[1];
                      var projectName$2 = action[0];
                      var bookmarkedViews$2 = accumulator.bookmarkedViews;
                      var currentProjectsBookmarkedViews$2 = Belt_Option.getWithDefault(Js_dict.get(accumulator.bookmarkedViews, projectName$2), []);
                      var nextBookmarkedViews$2 = Belt_Array.keep(currentProjectsBookmarkedViews$2, (function (view$prime) {
                              return view$prime !== view;
                            }));
                      bookmarkedViews$2[projectName$2] = nextBookmarkedViews$2;
                      return {
                              projectDefaultViews: accumulator.projectDefaultViews,
                              bookmarkedViews: bookmarkedViews$2,
                              reopenLatestSelectedAttribute: accumulator.reopenLatestSelectedAttribute,
                              reopenLatestSelectedType: accumulator.reopenLatestSelectedType,
                              reopenLatestAttributeValues: accumulator.reopenLatestAttributeValues,
                              reopenLatestSelectedTimePresent: accumulator.reopenLatestSelectedTimePresent,
                              unityCDA: accumulator.unityCDA,
                              firstTimeExperience: accumulator.firstTimeExperience,
                              highlightbarAttributes: accumulator.highlightbarAttributes,
                              debugViewToggles: accumulator.debugViewToggles,
                              languageCode: accumulator.languageCode,
                              fingerprintDetailsAttrs: accumulator.fingerprintDetailsAttrs,
                              nlqRecentQuestions: accumulator.nlqRecentQuestions
                            };
                  case /* SetReopenLatestSelectedType */3 :
                      return {
                              projectDefaultViews: accumulator.projectDefaultViews,
                              bookmarkedViews: accumulator.bookmarkedViews,
                              reopenLatestSelectedAttribute: accumulator.reopenLatestSelectedAttribute,
                              reopenLatestSelectedType: action[0],
                              reopenLatestAttributeValues: accumulator.reopenLatestAttributeValues,
                              reopenLatestSelectedTimePresent: accumulator.reopenLatestSelectedTimePresent,
                              unityCDA: accumulator.unityCDA,
                              firstTimeExperience: accumulator.firstTimeExperience,
                              highlightbarAttributes: accumulator.highlightbarAttributes,
                              debugViewToggles: accumulator.debugViewToggles,
                              languageCode: accumulator.languageCode,
                              fingerprintDetailsAttrs: accumulator.fingerprintDetailsAttrs,
                              nlqRecentQuestions: accumulator.nlqRecentQuestions
                            };
                  case /* SetReopenLatestSelectedAttribute */4 :
                      return {
                              projectDefaultViews: accumulator.projectDefaultViews,
                              bookmarkedViews: accumulator.bookmarkedViews,
                              reopenLatestSelectedAttribute: action[0],
                              reopenLatestSelectedType: accumulator.reopenLatestSelectedType,
                              reopenLatestAttributeValues: accumulator.reopenLatestAttributeValues,
                              reopenLatestSelectedTimePresent: accumulator.reopenLatestSelectedTimePresent,
                              unityCDA: accumulator.unityCDA,
                              firstTimeExperience: accumulator.firstTimeExperience,
                              highlightbarAttributes: accumulator.highlightbarAttributes,
                              debugViewToggles: accumulator.debugViewToggles,
                              languageCode: accumulator.languageCode,
                              fingerprintDetailsAttrs: accumulator.fingerprintDetailsAttrs,
                              nlqRecentQuestions: accumulator.nlqRecentQuestions
                            };
                  case /* SetReopenLatestAttributeValue */5 :
                      var match = action[0];
                      var k = match[0];
                      var attributeValues = Belt_Option.getWithDefault(accumulator.reopenLatestAttributeValues, /* [] */0);
                      return {
                              projectDefaultViews: accumulator.projectDefaultViews,
                              bookmarkedViews: accumulator.bookmarkedViews,
                              reopenLatestSelectedAttribute: accumulator.reopenLatestSelectedAttribute,
                              reopenLatestSelectedType: accumulator.reopenLatestSelectedType,
                              reopenLatestAttributeValues: Belt_List.concat(Belt_List.keep(attributeValues, (function (param) {
                                          return param[0] !== k;
                                        })), /* :: */[
                                    /* tuple */[
                                      k,
                                      match[1]
                                    ],
                                    /* [] */0
                                  ]),
                              reopenLatestSelectedTimePresent: accumulator.reopenLatestSelectedTimePresent,
                              unityCDA: accumulator.unityCDA,
                              firstTimeExperience: accumulator.firstTimeExperience,
                              highlightbarAttributes: accumulator.highlightbarAttributes,
                              debugViewToggles: accumulator.debugViewToggles,
                              languageCode: accumulator.languageCode,
                              fingerprintDetailsAttrs: accumulator.fingerprintDetailsAttrs,
                              nlqRecentQuestions: accumulator.nlqRecentQuestions
                            };
                  case /* SetReopenLastestSelectedTimePreset */6 :
                      return {
                              projectDefaultViews: accumulator.projectDefaultViews,
                              bookmarkedViews: accumulator.bookmarkedViews,
                              reopenLatestSelectedAttribute: accumulator.reopenLatestSelectedAttribute,
                              reopenLatestSelectedType: accumulator.reopenLatestSelectedType,
                              reopenLatestAttributeValues: accumulator.reopenLatestAttributeValues,
                              reopenLatestSelectedTimePresent: action[0],
                              unityCDA: accumulator.unityCDA,
                              firstTimeExperience: accumulator.firstTimeExperience,
                              highlightbarAttributes: accumulator.highlightbarAttributes,
                              debugViewToggles: accumulator.debugViewToggles,
                              languageCode: accumulator.languageCode,
                              fingerprintDetailsAttrs: accumulator.fingerprintDetailsAttrs,
                              nlqRecentQuestions: accumulator.nlqRecentQuestions
                            };
                  case /* SetDefaultHighlightBarAttributes */7 :
                      var highlightbarKey = action[0] + action[1];
                      var highlightbarAttributes = accumulator.highlightbarAttributes;
                      var currentProjectSdkHighlightbarAttributes = Belt_Option.getWithDefault(Js_dict.get(accumulator.highlightbarAttributes, highlightbarKey), []);
                      var nextHighlightBarAttribute = Util$BsConsole.getUniqueValues(Belt_Array.slice(Belt_Array.concat(currentProjectSdkHighlightbarAttributes, action[2]), 0, BugHighlightBarDefaults$BsConsole.maxHighlightbarAttributes));
                      highlightbarAttributes[highlightbarKey] = nextHighlightBarAttribute;
                      return {
                              projectDefaultViews: accumulator.projectDefaultViews,
                              bookmarkedViews: accumulator.bookmarkedViews,
                              reopenLatestSelectedAttribute: accumulator.reopenLatestSelectedAttribute,
                              reopenLatestSelectedType: accumulator.reopenLatestSelectedType,
                              reopenLatestAttributeValues: accumulator.reopenLatestAttributeValues,
                              reopenLatestSelectedTimePresent: accumulator.reopenLatestSelectedTimePresent,
                              unityCDA: accumulator.unityCDA,
                              firstTimeExperience: accumulator.firstTimeExperience,
                              highlightbarAttributes: highlightbarAttributes,
                              debugViewToggles: accumulator.debugViewToggles,
                              languageCode: accumulator.languageCode,
                              fingerprintDetailsAttrs: accumulator.fingerprintDetailsAttrs,
                              nlqRecentQuestions: accumulator.nlqRecentQuestions
                            };
                  case /* AddHighlightBarAttribute */8 :
                      var highlightbarKey$1 = action[0] + action[1];
                      var highlightbarAttributes$1 = accumulator.highlightbarAttributes;
                      var currentProjectSdkHighlightbarAttributes$1 = Belt_Option.getWithDefault(Js_dict.get(accumulator.highlightbarAttributes, highlightbarKey$1), []);
                      var nextHighlightBarAttribute$1 = Util$BsConsole.getUniqueValues(Belt_Array.slice(Belt_Array.concat(currentProjectSdkHighlightbarAttributes$1, [action[2]]), 0, BugHighlightBarDefaults$BsConsole.maxHighlightbarAttributes));
                      highlightbarAttributes$1[highlightbarKey$1] = nextHighlightBarAttribute$1;
                      return {
                              projectDefaultViews: accumulator.projectDefaultViews,
                              bookmarkedViews: accumulator.bookmarkedViews,
                              reopenLatestSelectedAttribute: accumulator.reopenLatestSelectedAttribute,
                              reopenLatestSelectedType: accumulator.reopenLatestSelectedType,
                              reopenLatestAttributeValues: accumulator.reopenLatestAttributeValues,
                              reopenLatestSelectedTimePresent: accumulator.reopenLatestSelectedTimePresent,
                              unityCDA: accumulator.unityCDA,
                              firstTimeExperience: accumulator.firstTimeExperience,
                              highlightbarAttributes: highlightbarAttributes$1,
                              debugViewToggles: accumulator.debugViewToggles,
                              languageCode: accumulator.languageCode,
                              fingerprintDetailsAttrs: accumulator.fingerprintDetailsAttrs,
                              nlqRecentQuestions: accumulator.nlqRecentQuestions
                            };
                  case /* RemoveHighlightBarAttribute */9 :
                      var attribute = action[2];
                      var highlightbarKey$2 = action[0] + action[1];
                      var highlightbarAttributes$2 = accumulator.highlightbarAttributes;
                      var currentProjectSdkHighlightbarAttributes$2 = Belt_Option.getWithDefault(Js_dict.get(accumulator.highlightbarAttributes, highlightbarKey$2), []);
                      var nextHighlightBarAttribute$2 = Belt_Array.keep(currentProjectSdkHighlightbarAttributes$2, (function (attribute$prime) {
                              return attribute$prime !== attribute;
                            }));
                      highlightbarAttributes$2[highlightbarKey$2] = nextHighlightBarAttribute$2;
                      return {
                              projectDefaultViews: accumulator.projectDefaultViews,
                              bookmarkedViews: accumulator.bookmarkedViews,
                              reopenLatestSelectedAttribute: accumulator.reopenLatestSelectedAttribute,
                              reopenLatestSelectedType: accumulator.reopenLatestSelectedType,
                              reopenLatestAttributeValues: accumulator.reopenLatestAttributeValues,
                              reopenLatestSelectedTimePresent: accumulator.reopenLatestSelectedTimePresent,
                              unityCDA: accumulator.unityCDA,
                              firstTimeExperience: accumulator.firstTimeExperience,
                              highlightbarAttributes: highlightbarAttributes$2,
                              debugViewToggles: accumulator.debugViewToggles,
                              languageCode: accumulator.languageCode,
                              fingerprintDetailsAttrs: accumulator.fingerprintDetailsAttrs,
                              nlqRecentQuestions: accumulator.nlqRecentQuestions
                            };
                  case /* DebuggerToggleCondenseFunctions */10 :
                      var debugToggles = Belt_Option.getWithDefault(accumulator.debugViewToggles, $$default$1);
                      return {
                              projectDefaultViews: accumulator.projectDefaultViews,
                              bookmarkedViews: accumulator.bookmarkedViews,
                              reopenLatestSelectedAttribute: accumulator.reopenLatestSelectedAttribute,
                              reopenLatestSelectedType: accumulator.reopenLatestSelectedType,
                              reopenLatestAttributeValues: accumulator.reopenLatestAttributeValues,
                              reopenLatestSelectedTimePresent: accumulator.reopenLatestSelectedTimePresent,
                              unityCDA: accumulator.unityCDA,
                              firstTimeExperience: accumulator.firstTimeExperience,
                              highlightbarAttributes: accumulator.highlightbarAttributes,
                              debugViewToggles: {
                                condenseFunctions: action[0],
                                showVariables: debugToggles.showVariables,
                                showBugTabsDataRaw: debugToggles.showBugTabsDataRaw
                              },
                              languageCode: accumulator.languageCode,
                              fingerprintDetailsAttrs: accumulator.fingerprintDetailsAttrs,
                              nlqRecentQuestions: accumulator.nlqRecentQuestions
                            };
                  case /* DebuggerToggleShowVariables */11 :
                      var debugToggles$1 = Belt_Option.getWithDefault(accumulator.debugViewToggles, $$default$1);
                      return {
                              projectDefaultViews: accumulator.projectDefaultViews,
                              bookmarkedViews: accumulator.bookmarkedViews,
                              reopenLatestSelectedAttribute: accumulator.reopenLatestSelectedAttribute,
                              reopenLatestSelectedType: accumulator.reopenLatestSelectedType,
                              reopenLatestAttributeValues: accumulator.reopenLatestAttributeValues,
                              reopenLatestSelectedTimePresent: accumulator.reopenLatestSelectedTimePresent,
                              unityCDA: accumulator.unityCDA,
                              firstTimeExperience: accumulator.firstTimeExperience,
                              highlightbarAttributes: accumulator.highlightbarAttributes,
                              debugViewToggles: {
                                condenseFunctions: debugToggles$1.condenseFunctions,
                                showVariables: action[0],
                                showBugTabsDataRaw: debugToggles$1.showBugTabsDataRaw
                              },
                              languageCode: accumulator.languageCode,
                              fingerprintDetailsAttrs: accumulator.fingerprintDetailsAttrs,
                              nlqRecentQuestions: accumulator.nlqRecentQuestions
                            };
                  case /* DebuggerToggleShowBugTabsDataRow */12 :
                      var debugToggles$2 = Belt_Option.getWithDefault(accumulator.debugViewToggles, $$default$1);
                      return {
                              projectDefaultViews: accumulator.projectDefaultViews,
                              bookmarkedViews: accumulator.bookmarkedViews,
                              reopenLatestSelectedAttribute: accumulator.reopenLatestSelectedAttribute,
                              reopenLatestSelectedType: accumulator.reopenLatestSelectedType,
                              reopenLatestAttributeValues: accumulator.reopenLatestAttributeValues,
                              reopenLatestSelectedTimePresent: accumulator.reopenLatestSelectedTimePresent,
                              unityCDA: accumulator.unityCDA,
                              firstTimeExperience: accumulator.firstTimeExperience,
                              highlightbarAttributes: accumulator.highlightbarAttributes,
                              debugViewToggles: {
                                condenseFunctions: debugToggles$2.condenseFunctions,
                                showVariables: debugToggles$2.showVariables,
                                showBugTabsDataRaw: action[0]
                              },
                              languageCode: accumulator.languageCode,
                              fingerprintDetailsAttrs: accumulator.fingerprintDetailsAttrs,
                              nlqRecentQuestions: accumulator.nlqRecentQuestions
                            };
                  case /* SetLanguageCode */13 :
                      return {
                              projectDefaultViews: accumulator.projectDefaultViews,
                              bookmarkedViews: accumulator.bookmarkedViews,
                              reopenLatestSelectedAttribute: accumulator.reopenLatestSelectedAttribute,
                              reopenLatestSelectedType: accumulator.reopenLatestSelectedType,
                              reopenLatestAttributeValues: accumulator.reopenLatestAttributeValues,
                              reopenLatestSelectedTimePresent: accumulator.reopenLatestSelectedTimePresent,
                              unityCDA: accumulator.unityCDA,
                              firstTimeExperience: accumulator.firstTimeExperience,
                              highlightbarAttributes: accumulator.highlightbarAttributes,
                              debugViewToggles: accumulator.debugViewToggles,
                              languageCode: action[0],
                              fingerprintDetailsAttrs: accumulator.fingerprintDetailsAttrs,
                              nlqRecentQuestions: accumulator.nlqRecentQuestions
                            };
                  
                }
              }));
}

function reduce(token, uid, actions, isGuest, onSuccess, onFailure) {
  return updateOrCreate(token, uid, isGuest, (function (frontendSettings) {
                var settings = frontendSettings !== undefined ? frontendSettings : empty;
                return getNextSettings(actions, settings);
              }), onSuccess, onFailure);
}

exports.UnityCDA = UnityCDA;
exports.FirstTimeExperience = FirstTimeExperience;
exports.DebuggerToggles = DebuggerToggles;
exports.empty = empty;
exports.ofJson = ofJson$3;
exports.toJson = toJson$3;
exports.toBPG = toBPG;
exports.getUnparsedSettingsFromBpg = getUnparsedSettingsFromBpg;
exports.getParsedSettingsFromBpg = getParsedSettingsFromBpg;
exports.deleteUserSettingsForUser = deleteUserSettingsForUser;
exports.getBookmarks = getBookmarks;
exports.getFirstTimeUserExperience = getFirstTimeUserExperience;
exports.getDefaultView = getDefaultView;
exports.updateOrCreate = updateOrCreate;
exports.getNextSettings = getNextSettings;
exports.reduce = reduce;
/* Util-BsConsole Not a pure module */
