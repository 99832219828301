// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var I18N$BsConsole = require("../I18N.js");
var Input$BsConsole = require("../Input.js");
var Colors$BsConsole = require("../Colors.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var Add = require("@material-ui/icons/Add");
var Paper = require("@material-ui/core/Paper");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var NaturalLanguageApi$BsConsole = require("../natural-language/NaturalLanguageApi.js");

function NlqQuestionBar$QuestionInput(Props) {
  var onChange = Props.onChange;
  var onDelete = Props.onDelete;
  var value = Props.value;
  var setValue = Props.setValue;
  var placeholderOpt = Props.placeholder;
  var onBlur = Props.onBlur;
  var autoFocusOpt = Props.autoFocus;
  var onArrowUp = Props.onArrowUp;
  var onArrowDown = Props.onArrowDown;
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : I18N$BsConsole.get(undefined, "[ value ]");
  var autoFocus = autoFocusOpt !== undefined ? autoFocusOpt : true;
  var handleKeyDown = function ($$event) {
    var match = $$event.key;
    switch (match) {
      case "ArrowDown" :
          return Curry._1(onArrowDown, undefined);
      case "ArrowUp" :
          return Curry._1(onArrowUp, undefined);
      case "Backspace" :
          if (value === "") {
            return Curry._1(onDelete, undefined);
          } else {
            return ;
          }
      case "Enter" :
          return Curry._1(onChange, value);
      case "Escape" :
          return Curry._1(onDelete, undefined);
      case "Tab" :
          return Curry._1(onBlur, undefined);
      default:
        return ;
    }
  };
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.display(/* flex */-1010954439),
                    /* :: */[
                      Css.alignItems(/* center */98248149),
                      /* [] */0
                    ]
                  ]),
              onKeyDown: handleKeyDown
            }, React.createElement(Input$BsConsole.make, {
                  className: Css.style(/* :: */[
                        Css.lineHeight(Css.rem(2)),
                        /* [] */0
                      ]),
                  onBlur: onBlur,
                  placeholder: placeholder,
                  value: value,
                  onChange: (function (e) {
                      return Curry._1(setValue, e.target.value);
                    }),
                  autoFocus: autoFocus,
                  ariaLabel: "value",
                  maxWidth: ""
                }));
}

var QuestionInput = {
  make: NlqQuestionBar$QuestionInput
};

function highlightedIndex(index, highlightedIndex$1) {
  if (Caml_obj.caml_equal(index, highlightedIndex$1)) {
    return Css.style(/* :: */[
                Css.backgroundColor(Css.hex(Colors$BsConsole.highlightedItem)),
                /* :: */[
                  Css.selector("svg", /* :: */[
                        Css.color(Css.hex(Colors$BsConsole.white)),
                        /* [] */0
                      ]),
                  /* [] */0
                ]
              ]);
  } else {
    return Css.style(/* [] */0);
  }
}

var container = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.minHeight(Css.px(38)),
        /* :: */[
          Css.justifyContent(/* flexStart */662439529),
          /* :: */[
            Css.alignItems(/* center */98248149),
            /* :: */[
              Css.cursor(/* pointer */-786317123),
              /* :: */[
                Css.paddingLeft(Css.rem(0.875)),
                /* :: */[
                  Css.width(Css.pct(100)),
                  /* :: */[
                    Css.borderBottom(Css.px(1), Css.solid, Css.hex(Colors$BsConsole.grey2)),
                    /* :: */[
                      Css.overflow(/* hidden */-862584982),
                      /* :: */[
                        Css.hover(/* :: */[
                              Css.backgroundColor(Css.hex(Colors$BsConsole.blackA08)),
                              /* :: */[
                                Css.selector("svg", /* :: */[
                                      Css.color(Css.hex(Colors$BsConsole.white)),
                                      /* [] */0
                                    ]),
                                /* [] */0
                              ]
                            ]),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var header = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey55)),
      /* :: */[
        Css.fontWeight(/* `num */[
              5496390,
              500
            ]),
        /* :: */[
          Css.unsafe("cursor", "unset"),
          /* :: */[
            Css.hover(/* :: */[
                  Css.backgroundColor(/* transparent */582626130),
                  /* [] */0
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var Style = {
  highlightedIndex: highlightedIndex,
  container: container,
  header: header
};

function NlqQuestionBar(Props) {
  var setValue = Props.setValue;
  var onDelete = Props.onDelete;
  var onBlur = Props.onBlur;
  var value = Props.value;
  var containerFocused = Props.containerFocused;
  var projectName = Props.projectName;
  var handleChangeUrl = Props.handleChangeUrl;
  var token = Props.token;
  var url = Props.url;
  var attributes = Props.attributes;
  var suggestionStyles = Props.suggestionStyles;
  var suggestedQuestions = Props.suggestedQuestions;
  var recentQuestions = Props.recentQuestions;
  var handleAddRecentQuestion = Props.handleAddRecentQuestion;
  var setNlqLoading = Props.setNlqLoading;
  var match = React.useState((function () {
          return -1;
        }));
  var setHighlightIndex = match[1];
  var highlightIndex = match[0];
  var handleHighlightChange = function (i) {
    var newIndex = highlightIndex + i | 0;
    var choicesLength = Belt_Array.concat(recentQuestions, suggestedQuestions).length;
    if (newIndex >= -1 && newIndex < choicesLength) {
      return Curry._1(setHighlightIndex, (function (prev) {
                    return prev + i | 0;
                  }));
    }
    
  };
  var handleQuestion = function (question) {
    var onSuccess = function (resp) {
      var answer = resp.result;
      var newRoute = NaturalLanguageApi$BsConsole.buildRouteFromAnswer(answer, projectName);
      var url = NaturalLanguageApi$BsConsole.buildUrlFromAnswer(answer, projectName);
      var tid = NaturalLanguageApi$BsConsole.getTransactionID(projectName);
      NaturalLanguageApi$BsConsole.writeTranactionIdToLocalStorage(tid);
      NaturalLanguageApi$BsConsole.sendQuestionMetric(tid, url, resp, question);
      Curry._1(handleAddRecentQuestion, question);
      Curry._1(handleChangeUrl, newRoute);
      return Curry._1(setNlqLoading, (function (param) {
                    return false;
                  }));
    };
    var onFailure = function (_errorMessage) {
      Curry._1(setNlqLoading, (function (param) {
              return false;
            }));
      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "There was an error with Natural Language Queries. It has been reported"));
    };
    Curry._1(setNlqLoading, (function (param) {
            return true;
          }));
    NaturalLanguageApi$BsConsole.askQuestion(question, token, url, Belt_List.toArray(attributes), onSuccess, onFailure);
    Curry._1(setValue, "");
    return Curry._1(onDelete, undefined);
  };
  React.useEffect((function () {
          var choices = Belt_Array.concat(recentQuestions, suggestedQuestions);
          var choice = Belt_Option.getWithDefault(Belt_Array.get(choices, highlightIndex), "");
          Curry._1(setValue, choice);
          
        }), [highlightIndex]);
  return React.createElement(React.Fragment, undefined, React.createElement(NlqQuestionBar$QuestionInput, {
                  onChange: handleQuestion,
                  onDelete: onDelete,
                  value: value,
                  setValue: setValue,
                  placeholder: I18N$BsConsole.get(undefined, "Ask a question..."),
                  onBlur: onBlur,
                  autoFocus: false,
                  onArrowUp: (function (param) {
                      return handleHighlightChange(-1);
                    }),
                  onArrowDown: (function (param) {
                      return handleHighlightChange(1);
                    })
                }), containerFocused ? React.createElement(Paper.default, {
                    classes: {
                      root: Css.merge(/* :: */[
                            suggestionStyles,
                            /* [] */0
                          ])
                    },
                    children: null
                  }, recentQuestions.length !== 0 ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                              className: Css.merge(/* :: */[
                                    container,
                                    /* :: */[
                                      header,
                                      /* [] */0
                                    ]
                                  ])
                            }, I18N$BsConsole.show(undefined, "RECENTLY USED")), Belt_Array.mapWithIndex(recentQuestions, (function (i, question) {
                                return React.createElement("div", {
                                            className: Css.merge(/* :: */[
                                                  container,
                                                  /* :: */[
                                                    highlightedIndex(i, highlightIndex),
                                                    /* [] */0
                                                  ]
                                                ]),
                                            onClick: (function (param) {
                                                return handleQuestion(question);
                                              })
                                          }, React.createElement(Add.default, {
                                                className: Css.style(/* :: */[
                                                      Css.color(Css.hex(Colors$BsConsole.filterIcons)),
                                                      /* :: */[
                                                        Css.marginRight(Css.rem(0.8125)),
                                                        /* [] */0
                                                      ]
                                                    ])
                                              }), I18N$BsConsole.showSkip(question));
                              }))) : null, suggestedQuestions.length !== 0 ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                              className: Css.merge(/* :: */[
                                    container,
                                    /* :: */[
                                      header,
                                      /* [] */0
                                    ]
                                  ])
                            }, I18N$BsConsole.show(undefined, "TRY PROMPTS LIKE...")), Belt_Array.mapWithIndex(suggestedQuestions, (function (i, question) {
                                return React.createElement("div", {
                                            className: Css.merge(/* :: */[
                                                  container,
                                                  /* :: */[
                                                    highlightedIndex(i + recentQuestions.length | 0, highlightIndex),
                                                    /* [] */0
                                                  ]
                                                ]),
                                            onClick: (function (param) {
                                                return handleQuestion(question);
                                              })
                                          }, React.createElement(MuiIcons$BsConsole.AutoAwesome.make, {
                                                className: Css.style(/* :: */[
                                                      Css.color(Css.hex(Colors$BsConsole.filterIcons)),
                                                      /* :: */[
                                                        Css.marginRight(Css.rem(0.8125)),
                                                        /* :: */[
                                                          Css.fontSize(Css.rem(1.25)),
                                                          /* [] */0
                                                        ]
                                                      ]
                                                    ])
                                              }), I18N$BsConsole.showSkip(question));
                              }))) : null) : null);
}

var make = NlqQuestionBar;

exports.QuestionInput = QuestionInput;
exports.Style = Style;
exports.make = make;
/* container Not a pure module */
