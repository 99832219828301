// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var I18N$BsConsole = require("./I18N.js");
var Input$BsConsole = require("./Input.js");

function InputWithKeyEvents(Props) {
  var onChange = Props.onChange;
  var onDelete = Props.onDelete;
  var value = Props.value;
  var setValue = Props.setValue;
  var placeholderOpt = Props.placeholder;
  var onBlur = Props.onBlur;
  var autoFocusOpt = Props.autoFocus;
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : I18N$BsConsole.get(undefined, "[ value ]");
  var autoFocus = autoFocusOpt !== undefined ? autoFocusOpt : true;
  var handleKeyDown = function ($$event) {
    var match = $$event.key;
    switch (match) {
      case "Backspace" :
          if (value === "") {
            return Curry._1(onDelete, undefined);
          } else {
            return ;
          }
      case "Enter" :
          return Curry._1(onChange, value);
      case "Escape" :
          return Curry._1(onDelete, undefined);
      case "Tab" :
          return Curry._1(onBlur, undefined);
      default:
        return ;
    }
  };
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.display(/* flex */-1010954439),
                    /* :: */[
                      Css.alignItems(/* center */98248149),
                      /* [] */0
                    ]
                  ]),
              onKeyDown: handleKeyDown
            }, React.createElement(Input$BsConsole.make, {
                  className: Css.style(/* :: */[
                        Css.lineHeight(Css.rem(2)),
                        /* [] */0
                      ]),
                  onBlur: onBlur,
                  placeholder: placeholder,
                  value: value,
                  onChange: (function (e) {
                      return Curry._1(setValue, e.target.value);
                    }),
                  autoFocus: autoFocus,
                  ariaLabel: "value"
                }));
}

var make = InputWithKeyEvents;

exports.make = make;
/* Css Not a pure module */
