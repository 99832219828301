// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var AutoSizeInput$BsConsole = require("./AutoSizeInput.js");

function Input(Props) {
  var className = Props.className;
  var onBlur = Props.onBlur;
  var onFocus = Props.onFocus;
  var placeholder = Props.placeholder;
  var disabled = Props.disabled;
  var value = Props.value;
  var onChange = Props.onChange;
  var inputRef = Props.inputRef;
  var autoFocus = Props.autoFocus;
  var ariaLabel = Props.ariaLabel;
  var maxWidth = Props.maxWidth;
  return ReasonReact.element(undefined, undefined, AutoSizeInput$BsConsole.make(placeholder, value, undefined, disabled, onChange, onFocus, onBlur, className, inputRef, autoFocus, ariaLabel, undefined, maxWidth, []));
}

var make = Input;

exports.make = make;
/* ReasonReact Not a pure module */
