// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


var react = [
  "What is my buggiest browser in the last week?",
  "How many Chrome errors did I have yesterday?",
  "What unhandled exceptions do I have in the last month?",
  "Give me all error messages from last week without 404 in the string",
  "Errors last week with a screen width less than 950px"
];

var browser = [
  "What are the most common browser versions causing errors?",
  "What is the distribution of browser platforms causing errors?",
  "Which browsers had the most errors in the last week?",
  "What is the average window width of users experiencing errors?",
  "How many Chrome errors did I have yesterday?"
];

var reactNative = [
  "What are the most common error messages on Android devices?",
  "How many crashes have I had in the last week on iOS devices?",
  "What are the most frequent errors in the last month?",
  "Show me all errors from the last week that occurred on devices with a screen width less than 400px",
  "What is my buggiest device model?"
];

var android = [
  "What are the most common Android versions causing errors?",
  "How many errors are coming from Samsung devices?",
  "What are the top device models with the most errors?",
  "Show me all errors from the last week with the error message containing 'null'?",
  "What are the most common error types on Android 12?"
];

var iOS = [
  "What are the most common iOS versions causing errors?",
  "How many errors are there from iPhone 13 Pro Max?",
  "What are the most frequent error messages on iOS 15?",
  "What are the top device models with the most errors?",
  "What are the most common errors in the last week?"
];

var unity = [
  "What are the most common Unity versions causing errors in my project?",
  "What are the most common errors in the last month?",
  "How many errors have I had on Windows in the last week?",
  "What are the most frequent error messages in my Unity project?",
  "What are the most common device models causing errors in my project?"
];

var unreal = [
  "How many crashes have I had in the last week on Windows?",
  "What are the most frequent callstack signatures in my Unreal Engine project yesterday?",
  "Can I see my errors grouped by device model?",
  "What are the most common errors in my project on a specific CPU architecture?",
  "What are the most common errors in my Unreal Engine project?"
];

var nodeJS = [
  "What are the most common error messages in my application in the last month?",
  "How many errors have occurred on my Linux servers in the last week?",
  "What are the most frequent Node.js versions causing errors in my application?",
  "Show me all errors from the last 24 hours where the error message contains 'TypeError' and the hostname is 'my-server.example.com'.",
  "Can you show me my Unhandled Crashes by process age?"
];

var python = [
  "What are the most common Python versions causing errors?",
  "How many errors are there from Python code running on Linux?",
  "What are the top most frequent error messages in Python code?",
  "Show me all Python errors that occurred in the last week with the word 'TypeError' in the error message.",
  "What are the most common errors in the last month, grouped by the function where they occurred?"
];

var csharp = [
  "What is the most common error message in my application in the last month?",
  "How many errors occurred on Windows systems in the last week?",
  "What are the most frequent errors in the last 24 hours?",
  "Show me all errors that occurred on a specific version of the .NET framework (e.g., .NET 4.8)",
  "What are the errors that occurred on a specific CPU architecture (e.g., x64) in the last week?"
];

var $$console = [
  "What is the most common error message on Playstation in the last month?",
  "How many errors have been reported from Xbox in the last week?",
  "Can you show me Switch errors grouped by process age?",
  "What is the distribution of errors by console type (Xbox, Playstation, Switch) in the last 24 hours?",
  "What are unhandled Switch errors did I have yesterday?"
];

function sdkStringToVariant(param) {
  switch (param) {
    case "@backtrace/browser" :
        return /* Browser */1;
    case "@backtrace-labs/node" :
    case "@backtrace/node" :
        return /* Node */3;
    case "@backtrace/react" :
        return /* React */0;
    case "@backtrace/react-native" :
        return /* ReactNative */2;
    case "backtrace-android" :
        return /* Android */4;
    case "backtrace-cocoa" :
        return /* IOS */5;
    case "backtrace-csharp" :
        return /* CSharp */9;
    case "backtrace-python" :
        return /* Python */8;
    case "backtrace-unity" :
        return /* Unity */6;
    case "backtrace-unreald" :
        return /* Unreal */7;
    case "connector" :
        return /* Console */10;
    default:
      return /* Other */11;
  }
}

function sdkVariantToQuestions(param) {
  switch (param) {
    case /* React */0 :
        return react;
    case /* Browser */1 :
        return browser;
    case /* ReactNative */2 :
        return reactNative;
    case /* Node */3 :
        return nodeJS;
    case /* Android */4 :
        return android;
    case /* IOS */5 :
        return iOS;
    case /* Unity */6 :
        return unity;
    case /* Unreal */7 :
        return unreal;
    case /* Python */8 :
        return python;
    case /* CSharp */9 :
        return csharp;
    case /* Console */10 :
        return $$console;
    case /* Other */11 :
        return [];
    
  }
}

exports.react = react;
exports.browser = browser;
exports.reactNative = reactNative;
exports.android = android;
exports.iOS = iOS;
exports.unity = unity;
exports.unreal = unreal;
exports.nodeJS = nodeJS;
exports.python = python;
exports.csharp = csharp;
exports.$$console = $$console;
exports.sdkStringToVariant = sdkStringToVariant;
exports.sdkVariantToQuestions = sdkVariantToQuestions;
/* No side effect */
