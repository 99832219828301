// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Crdb$BsConsole = require("./crdb.js");
var Gate$BsConsole = require("./Gate.js");
var I18N$BsConsole = require("./I18N.js");
var Link$BsConsole = require("./Link.js");
var Row2$BsConsole = require("./components/Row2.js");
var Route$BsConsole = require("./route.js");
var Colors$BsConsole = require("./Colors.js");
var BtPaper$BsConsole = require("./BtPaper.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var ReasonReactRouter = require("reason-react/lib/js/src/ReasonReactRouter.js");
var Version$BsConsole = require("./Version.js");
var GranSelect$BsConsole = require("./granSelect.js");
var FilterShell$BsConsole = require("./filterShell.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var NavBreadcrumb$BsConsole = require("./NavBreadcrumb.js");
var Popover = require("@material-ui/core/Popover");
var MenuItem = require("@material-ui/core/MenuItem");
var Settings = require("@material-ui/icons/Settings");
var SavedQueriesModel$BsConsole = require("./saved-queries/SavedQueriesModel.js");
var SavedQueriesContainer$BsConsole = require("./saved-queries/SavedQueriesContainer.js");
var ProjectSettingsNavBreadcrumbToolbar$BsConsole = require("./ProjectSettingsNavBreadcrumbToolbar.js");

function getUrl(currentUniverse, nextUniverse, origin) {
  var regex = new RegExp(currentUniverse, "i");
  var match = regex.exec(origin);
  if (match !== null) {
    return origin.replace(regex, nextUniverse);
  }
  
}

function nextRouteForCurrentRouteAndName(config, currentRoute, nextProjectName) {
  var userDefaultRoute = SavedQueriesModel$BsConsole.getRouteForUserDefault(config, nextProjectName);
  var projectDefaultRoute = SavedQueriesModel$BsConsole.getRouteForProjectDefault(config, nextProjectName);
  var match = ReasonReactRouter.dangerouslyGetInitialUrl(undefined).path;
  var useSavedQuery;
  if (match && match[0] === "p") {
    var match$1 = match[1];
    if (match$1) {
      var match$2 = match$1[1];
      useSavedQuery = match$2 && match$2[0] === "settings" ? false : true;
    } else {
      useSavedQuery = true;
    }
  } else {
    useSavedQuery = true;
  }
  if (useSavedQuery) {
    if (userDefaultRoute !== undefined) {
      return userDefaultRoute;
    }
    if (projectDefaultRoute !== undefined) {
      return projectDefaultRoute;
    }
    
  }
  if (typeof currentRoute === "number") {
    return ;
  }
  switch (currentRoute.tag | 0) {
    case /* ProjectOverview */8 :
        return /* ProjectOverview */Block.__(8, [
                  nextProjectName,
                  {
                    aperture: Crdb$BsConsole.Aperture.$$default
                  }
                ]);
    case /* ProjectCompareReleases */9 :
        return /* ProjectCompareReleases */Block.__(9, [
                  nextProjectName,
                  undefined,
                  {
                    aperture: Crdb$BsConsole.Aperture.$$default,
                    groups: undefined
                  }
                ]);
    case /* ProjectUpload */10 :
        return /* ProjectUpload */Block.__(10, [nextProjectName]);
    case /* ProjectTriage */11 :
        return /* ProjectTriage */Block.__(11, [
                  nextProjectName,
                  Route$BsConsole.getInboxParams(nextProjectName, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
                  undefined
                ]);
    case /* ProjectQueryBuilder */13 :
        return /* ProjectQueryBuilder */Block.__(13, [
                  nextProjectName,
                  Route$BsConsole.getQueryBuilderParams(undefined, undefined, nextProjectName, undefined, undefined, undefined, undefined, undefined, undefined)
                ]);
    case /* ProjectInstances */15 :
        return /* ProjectInstances */Block.__(15, [
                  nextProjectName,
                  Route$BsConsole.getInstancesParams(nextProjectName, undefined, undefined)
                ]);
    case /* ProjectDebugger */17 :
        return /* ProjectDebugger */Block.__(17, [
                  nextProjectName,
                  Route$BsConsole.getDebuggerParams(undefined, undefined, nextProjectName, undefined)
                ]);
    case /* ProjectDebugger2 */19 :
        return /* ProjectDebugger2 */Block.__(19, [
                  nextProjectName,
                  {
                    aperture: Crdb$BsConsole.Aperture.$$default,
                    state: undefined
                  }
                ]);
    case /* ProjectFlamegraph */20 :
        return /* ProjectFlamegraph */Block.__(20, [
                  nextProjectName,
                  Route$BsConsole.getFlamegraphParams(undefined, undefined, nextProjectName, undefined, undefined)
                ]);
    case /* ProjectSimilarity */21 :
        return /* ProjectSimilarity */Block.__(21, [
                  nextProjectName,
                  Route$BsConsole.getQueryBuilderParams(undefined, undefined, nextProjectName, undefined, undefined, undefined, undefined, undefined, undefined)
                ]);
    case /* ProjectSettings */22 :
        return /* ProjectSettings */Block.__(22, [nextProjectName]);
    case /* ProjectSettingsMetricsExporter */24 :
        return /* ProjectSettingsMetricsExporter */Block.__(24, [nextProjectName]);
    case /* ProjectSettingsScrubbers */25 :
        return /* ProjectSettingsScrubbers */Block.__(25, [nextProjectName]);
    case /* ProjectSettingsServerSideActions */26 :
        return /* ProjectSettingsServerSideActions */Block.__(26, [nextProjectName]);
    case /* ProjectSettingsAlerts */27 :
        return /* ProjectSettingsAlerts */Block.__(27, [
                  nextProjectName,
                  currentRoute[1]
                ]);
    case /* ProjectSettingsIntegratedDocs */28 :
        return /* ProjectSettingsIntegratedDocs */Block.__(28, [
                  nextProjectName,
                  {
                    doc: undefined
                  }
                ]);
    case /* ProjectSettingsSubmission */29 :
        return /* ProjectSettingsSubmission */Block.__(29, [nextProjectName]);
    case /* ProjectSettingsSubmissionTokens */31 :
        return /* ProjectSettingsSubmissionTokens */Block.__(31, [nextProjectName]);
    case /* ProjectSettingStorageSampling */32 :
        return /* ProjectSettingStorageSampling */Block.__(32, [nextProjectName]);
    case /* ProjectSettingsSubmissionDataSources */33 :
        return /* ProjectSettingsSubmissionDataSources */Block.__(33, [nextProjectName]);
    case /* ProjectSettingsAttributes */34 :
        return /* ProjectSettingsAttributes */Block.__(34, [
                  nextProjectName,
                  Route$BsConsole.ProjectSettingsAttributesParams.empty
                ]);
    case /* ProjectSettingsIntegrations */35 :
        return /* ProjectSettingsIntegrations */Block.__(35, [
                  nextProjectName,
                  currentRoute[1],
                  currentRoute[2]
                ]);
    case /* ProjectSettingsSymbolsUploadedArchives */36 :
        return /* ProjectSettingsSymbolsUploadedArchives */Block.__(36, [nextProjectName]);
    case /* ProjectSettingsSymbolsUpload */37 :
        return /* ProjectSettingsSymbolsUpload */Block.__(37, [nextProjectName]);
    case /* ProjectSettingsSymbolServers */38 :
        return /* ProjectSettingsSymbolServers */Block.__(38, [nextProjectName]);
    case /* ProjectSettingsSymbols */39 :
        return /* ProjectSettingsSymbols */Block.__(39, [nextProjectName]);
    case /* ProjectSettingsSymbolAccessTokens */40 :
        return /* ProjectSettingsSymbolAccessTokens */Block.__(40, [nextProjectName]);
    case /* ProjectSettingsMissingSymbols */41 :
        return /* ProjectSettingsMissingSymbols */Block.__(41, [nextProjectName]);
    case /* ProjectSettingsSymbolSearch */42 :
        return /* ProjectSettingsSymbolSearch */Block.__(42, [nextProjectName]);
    case /* ProjectSettingsScheduledReports */43 :
        return /* ProjectSettingsScheduledReports */Block.__(43, [nextProjectName]);
    case /* ProjectSettingsReprocessing */45 :
        return /* ProjectSettingsReprocessing */Block.__(45, [nextProjectName]);
    case /* ProjectSettingsRetentionPolicy */46 :
        return /* ProjectSettingsRetentionPolicy */Block.__(46, [nextProjectName]);
    case /* ProjectSettingsSourceCode */47 :
        return /* ProjectSettingsSourceCode */Block.__(47, [nextProjectName]);
    case /* ProjectSettingsApiTokens */48 :
        return /* ProjectSettingsApiTokens */Block.__(48, [nextProjectName]);
    case /* ProjectSettingsDedupeConfig */49 :
        return /* ProjectSettingsDedupeConfig */Block.__(49, [
                  nextProjectName,
                  undefined
                ]);
    case /* ProjectSettingsStabilityMonitoring */50 :
        return /* ProjectSettingsStabilityMonitoring */Block.__(50, [nextProjectName]);
    default:
      return ;
  }
}

function handleApertureHavingChange(route, aperture, havings) {
  if (typeof route === "number") {
    throw [
          Caml_builtin_exceptions.assert_failure,
          /* tuple */[
            "IFrameAppBar.re",
            161,
            9
          ]
        ];
  }
  switch (route.tag | 0) {
    case /* ProjectOverview */8 :
        return /* ProjectOverview */Block.__(8, [
                  route[0],
                  {
                    aperture: aperture
                  }
                ]);
    case /* ProjectCompareReleases */9 :
        return /* ProjectCompareReleases */Block.__(9, [
                  route[0],
                  route[1],
                  {
                    aperture: aperture,
                    groups: route[2].groups
                  }
                ]);
    case /* ProjectTriage */11 :
        var qs = route[1];
        return /* ProjectTriage */Block.__(11, [
                  route[0],
                  {
                    aperture: aperture,
                    sort: qs.sort,
                    stats: qs.stats,
                    havings: havings,
                    fold: qs.fold,
                    normBy: qs.normBy,
                    similarity: qs.similarity
                  },
                  undefined
                ]);
    case /* ProjectQueryBuilder */13 :
        var qs$1 = route[1];
        return /* ProjectQueryBuilder */Block.__(13, [
                  route[0],
                  {
                    aperture: aperture,
                    factor: qs$1.factor,
                    havings: havings,
                    fold: qs$1.fold,
                    sort: qs$1.sort,
                    normBy: qs$1.normBy,
                    highlightExporter: qs$1.highlightExporter
                  }
                ]);
    case /* ProjectInstances */15 :
        var qs$2 = route[1];
        return /* ProjectInstances */Block.__(15, [
                  route[0],
                  {
                    aperture: aperture,
                    select: qs$2.select,
                    sort: qs$2.sort
                  }
                ]);
    case /* ProjectDebugger */17 :
        var qs$3 = route[1];
        return /* ProjectDebugger */Block.__(17, [
                  route[0],
                  {
                    aperture: aperture,
                    sort: qs$3.sort,
                    s: qs$3.s
                  }
                ]);
    case /* ProjectDebugger2 */19 :
        return /* ProjectDebugger2 */Block.__(19, [
                  route[0],
                  {
                    aperture: aperture,
                    state: route[1].state
                  }
                ]);
    case /* ProjectFlamegraph */20 :
        var qs$4 = route[1];
        return /* ProjectFlamegraph */Block.__(20, [
                  route[0],
                  {
                    aperture: aperture,
                    weighted: qs$4.weighted,
                    reversed: qs$4.reversed
                  }
                ]);
    case /* ProjectSimilarity */21 :
        var qs$5 = route[1];
        return /* ProjectSimilarity */Block.__(21, [
                  route[0],
                  {
                    aperture: aperture,
                    factor: qs$5.factor,
                    havings: qs$5.havings,
                    fold: qs$5.fold,
                    sort: qs$5.sort,
                    normBy: qs$5.normBy,
                    highlightExporter: qs$5.highlightExporter
                  }
                ]);
    default:
      throw [
            Caml_builtin_exceptions.assert_failure,
            /* tuple */[
              "IFrameAppBar.re",
              161,
              9
            ]
          ];
  }
}

function getNormBy(route) {
  if (typeof route === "number") {
    return ;
  }
  switch (route.tag | 0) {
    case /* ProjectTriage */11 :
        if (route[2] !== undefined) {
          return ;
        } else {
          return route[1].normBy;
        }
    case /* ProjectQueryBuilder */13 :
        return route[1].normBy;
    default:
      return ;
  }
}

var spacer = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.flexGrow(1),
            /* [] */0
          ]),
      /* :: */[
        "spacer",
        /* [] */0
      ]
    ]);

var granSelect = Css.merge(/* :: */[
      BtPaper$BsConsole.unityPaperRaw,
      /* :: */[
        Css.style(/* :: */[
              Css.marginRight(Css.rem(1)),
              /* :: */[
                Css.backgroundColor(Css.hex(Colors$BsConsole.white)),
                /* [] */0
              ]
            ]),
        /* [] */0
      ]
    ]);

function filterShellContainer(focused) {
  if (focused) {
    return Css.merge(/* :: */[
                Css.style(/* :: */[
                      Css.unsafe("height", "unset"),
                      /* :: */[
                        Css.position(/* absolute */-1013592457),
                        /* :: */[
                          Css.top(/* zero */-789508312),
                          /* :: */[
                            Css.left(/* zero */-789508312),
                            /* :: */[
                              Css.zIndex(4000),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]),
                /* :: */[
                  BtPaper$BsConsole.unityPaperRaw,
                  /* [] */0
                ]
              ]);
  } else {
    return BtPaper$BsConsole.unityPaperRaw;
  }
}

var Styles = {
  spacer: spacer,
  granSelect: granSelect,
  filterShellContainer: filterShellContainer
};

function IFrameAppBar(Props) {
  var token = Props.token;
  var handleTask = Props.handleTask;
  var aperture = Props.aperture;
  var query = Props.query;
  var mode = Props.mode;
  var route = Props.route;
  var handleChangeUrl = Props.handleChangeUrl;
  var projectName = Props.projectName;
  var configRemote = Props.configRemote;
  var match = React.useState((function () {
          
        }));
  var setAnchorEl = match[1];
  var anchorEl = match[0];
  var handleOnClick = React.useCallback((function ($$event) {
          var el = $$event.currentTarget;
          if (!(el == null)) {
            return Curry._1(setAnchorEl, (function (param) {
                          return Caml_option.some(el);
                        }));
          }
          
        }), [setAnchorEl]);
  var ver = window._BACKTRACE_CORONER_VERSION;
  var coronerdVersion = (ver == null) ? I18N$BsConsole.get(undefined, "Unknown") : ver;
  var match$1 = ProjectSettingsNavBreadcrumbToolbar$BsConsole.getFriendlyNameForRoute(route);
  var tmp;
  if (aperture !== undefined) {
    var aperture$1 = Caml_option.valFromOption(aperture);
    var tmp$1;
    tmp$1 = typeof configRemote === "number" || configRemote.tag ? null : React.createElement(FilterShell$BsConsole.make, {
            onChange: (function (aperture, havings) {
                return Curry._1(handleChangeUrl, handleApertureHavingChange(route, aperture, havings));
              }),
            name: projectName,
            handleTask: handleTask,
            handleChangeUrl: handleChangeUrl,
            route: route,
            aperture: aperture$1,
            havings: Belt_Option.getWithDefault(Belt_Option.flatMap(query, (function (q) {
                        return Curry._1(Crdb$BsConsole.Query.getHavings, q);
                      })), Crdb$BsConsole.Havings.empty),
            toolbarClassName: Css.style(/* :: */[
                  Css.important(Css.unsafe("minHeight", "unset")),
                  /* [] */0
                ]),
            token: token,
            config: configRemote[0]
          });
    tmp = React.createElement(React.Fragment, undefined, React.createElement(GranSelect$BsConsole.Jsx3.make, {
              route: route,
              handleChangeUrl: handleChangeUrl,
              aperture: aperture$1,
              selectRootClassName: granSelect
            }), tmp$1);
  } else {
    tmp = match$1 !== undefined ? React.createElement(NavBreadcrumb$BsConsole.make, {
            current: match$1,
            trail: [
              /* tuple */[
                "Project diagnostics",
                /* Project */Block.__(7, [projectName])
              ],
              /* tuple */[
                "Project settings",
                /* ProjectSettings */Block.__(22, [projectName])
              ]
            ],
            handleChangeUrl: handleChangeUrl,
            className: Css.style(/* :: */[
                  Css.width(Css.pct(100)),
                  /* :: */[
                    Css.alignSelf(/* center */98248149),
                    /* [] */0
                  ]
                ])
          }) : React.createElement("div", {
            className: spacer
          });
  }
  var tmp$2;
  tmp$2 = typeof configRemote === "number" || configRemote.tag ? null : React.createElement(SavedQueriesContainer$BsConsole.make, {
          name: projectName,
          token: token,
          handleTask: handleTask,
          handleChangeUrl: handleChangeUrl,
          config: configRemote[0],
          query: query,
          mode: mode,
          normBy: getNormBy(route),
          renderTrigger: (function (param) {
              return null;
            })
        });
  return React.createElement(React.Fragment, undefined, React.createElement(Row2$BsConsole.make, {
                  alignItems: /* flexStart */662439529,
                  justifyContent: /* flexEnd */924268066,
                  className: Css.style(/* :: */[
                        Css.margin2(Css.zero, Css.px(1)),
                        /* [] */0
                      ]),
                  children: null
                }, tmp, React.createElement(Row2$BsConsole.make, {
                      alignItems: /* center */98248149,
                      className: Css.style(/* :: */[
                            Css.selector(".saved-views__icon-button", /* :: */[
                                  Css.color(Css.hex(Colors$BsConsole.blackA54)),
                                  /* :: */[
                                    Css.marginLeft(Css.px(4)),
                                    /* :: */[
                                      Css.padding(Css.px(8)),
                                      /* [] */0
                                    ]
                                  ]
                                ]),
                            /* [] */0
                          ]),
                      children: null
                    }, tmp$2, React.createElement(Mui$BsConsole.IconButton.make, {
                          ariaLabel: "Settings",
                          className: Css.style(/* :: */[
                                Css.color(Css.hex(Colors$BsConsole.blackA54)),
                                /* :: */[
                                  Css.marginLeft(Css.px(4)),
                                  /* :: */[
                                    Css.padding(Css.px(8)),
                                    /* [] */0
                                  ]
                                ]
                              ]),
                          onClick: handleOnClick,
                          children: React.createElement(Settings.default, { })
                        }))), React.createElement(Popover.default, {
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                  },
                  anchorEl: anchorEl,
                  open: Belt_Option.isSome(anchorEl),
                  onClose: (function (_event) {
                      return Curry._1(setAnchorEl, (function (param) {
                                    
                                  }));
                    }),
                  children: null
                }, React.createElement(Link$BsConsole.Jsx3.make, {
                      route: /* ProjectSettings */Block.__(22, [projectName]),
                      handleChangeUrl: handleChangeUrl,
                      children: React.createElement(MenuItem.default, {
                            className: "walkthrough--project_toolbar_menu_dropdown--project_settings",
                            children: I18N$BsConsole.show(undefined, "Settings")
                          })
                    }), React.createElement(Link$BsConsole.Jsx3.make, {
                      route: /* ProjectUpload */Block.__(10, [projectName]),
                      handleChangeUrl: handleChangeUrl,
                      children: React.createElement(MenuItem.default, {
                            className: "walkthrough--project_toolbar_menu_dropdown--upload_an_error",
                            children: I18N$BsConsole.show(undefined, "Upload an error")
                          })
                    }), React.createElement(MenuItem.default, {
                      disabled: true,
                      className: Css.style(/* :: */[
                            Css.fontSize(Css.rem(0.75)),
                            /* :: */[
                              Css.height(Css.rem(0.70)),
                              /* [] */0
                            ]
                          ]),
                      children: Curry._3(I18N$BsConsole.showSkipf(/* Format */[
                                /* String_literal */Block.__(11, [
                                    "Console ",
                                    /* String */Block.__(2, [
                                        /* No_padding */0,
                                        /* String */Block.__(2, [
                                            /* No_padding */0,
                                            /* String_literal */Block.__(11, [
                                                " - coronerd ",
                                                /* String */Block.__(2, [
                                                    /* No_padding */0,
                                                    /* End_of_format */0
                                                  ])
                                              ])
                                          ])
                                      ])
                                  ]),
                                "Console %s%s - coronerd %s"
                              ]), Version$BsConsole.version, Gate$BsConsole.isAlpha(undefined) ? "-alpha" : (
                              Gate$BsConsole.isBeta(undefined) ? "-beta" : ""
                            ), coronerdVersion)
                    })));
}

function make(token, handleTask, aperture, query, mode, route, handleChangeUrl, projectName, configRemote, _children) {
  var tmp = {
    token: token,
    handleTask: handleTask,
    route: route,
    handleChangeUrl: handleChangeUrl,
    projectName: projectName,
    configRemote: configRemote
  };
  if (aperture !== undefined) {
    tmp.aperture = Caml_option.valFromOption(aperture);
  }
  if (query !== undefined) {
    tmp.query = Caml_option.valFromOption(query);
  }
  if (mode !== undefined) {
    tmp.mode = Caml_option.valFromOption(mode);
  }
  return ReasonReactCompat.wrapReactForReasonReact(IFrameAppBar, tmp, _children);
}

var Jsx2 = {
  make: make
};

var make$1 = IFrameAppBar;

exports.getUrl = getUrl;
exports.nextRouteForCurrentRouteAndName = nextRouteForCurrentRouteAndName;
exports.handleApertureHavingChange = handleApertureHavingChange;
exports.getNormBy = getNormBy;
exports.Styles = Styles;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* spacer Not a pure module */
