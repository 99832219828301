// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var ReactInputAutosize = require("react-input-autosize");

function make(placeholderOpt, value, placeholderIsMinWidthOpt, disabledOpt, onChange, onFocus, onBlur, classNameOpt, inputRef, autoFocusOpt, ariaLabelOpt, propsOpt, maxWidthOpt, children) {
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : "";
  var placeholderIsMinWidth = placeholderIsMinWidthOpt !== undefined ? placeholderIsMinWidthOpt : false;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var autoFocus = autoFocusOpt !== undefined ? autoFocusOpt : false;
  var ariaLabel = ariaLabelOpt !== undefined ? ariaLabelOpt : "input";
  var props = propsOpt !== undefined ? Caml_option.valFromOption(propsOpt) : ({ });
  var maxWidth = maxWidthOpt !== undefined ? maxWidthOpt : "250px";
  return ReasonReact.wrapJsForReason(ReactInputAutosize.default, Object.assign(props, {
                  "aria-label": ariaLabel,
                  autoFocus: autoFocus,
                  className: className,
                  value: value,
                  placeholder: placeholder,
                  placeholderIsMinWidth: placeholderIsMinWidth,
                  disabled: disabled,
                  onChange: onChange,
                  onFocus: onFocus,
                  onBlur: onBlur,
                  inputRef: inputRef,
                  style: {
                    backgroundColor: "inherit",
                    color: "inherit",
                    fontSize: "1em"
                  },
                  inputStyle: {
                    backgroundColor: "inherit",
                    color: "inherit",
                    border: "none",
                    fontSize: "1em",
                    outline: "none",
                    padding: "2px 0",
                    maxWidth: maxWidth
                  }
                }), children);
}

exports.make = make;
/* ReasonReact Not a pure module */
