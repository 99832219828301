// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Col2$BsConsole = require("./components/Col2.js");
var Crdb$BsConsole = require("./crdb.js");
var Gate$BsConsole = require("./Gate.js");
var Row2$BsConsole = require("./components/Row2.js");
var Colors$BsConsole = require("./Colors.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");
var NlqSwitch$BsConsole = require("./filter-bar/NlqSwitch.js");
var TeamsUtils$BsConsole = require("./teams/TeamsUtils.js");
var FilterChips$BsConsole = require("./filter-bar/FilterChips.js");
var FilterBarIcon$BsConsole = require("./filter-bar/FilterBarIcon.js");
var InboxWorkflow$BsConsole = require("./inbox/InboxWorkflow.js");
var FilterContainer$BsConsole = require("./filter-bar/FilterContainer.js");
var ClearFiltersButton$BsConsole = require("./filter-bar/ClearFiltersButton.js");
var FilterQueryBuilder$BsConsole = require("./FilterQueryBuilder.js");
var FilterList = require("@material-ui/icons/FilterList");
var QueryPaginationTask$BsConsole = require("./QueryPaginationTask.js");
var UserFrontendSettings$BsConsole = require("./UserFrontendSettings.js");
var TotalFilterChipsCount$BsConsole = require("./filter-bar/TotalFilterChipsCount.js");
var CircularProgress = require("@material-ui/core/CircularProgress");
var SuggestedQuestionDefaults$BsConsole = require("./nlq/SuggestedQuestionDefaults.js");

function setContainerFocusStyle(isFilterOpen) {
  if (isFilterOpen) {
    return Css.style(/* :: */[
                Css.unsafe("boxShadow", "0px 6px 6px -3px " + (Colors$BsConsole.octothorpe(Colors$BsConsole.blackA20) + (", 0px 10px 14px 1px " + (Colors$BsConsole.octothorpe(Colors$BsConsole.blackA14) + (", 0px 4px 18px 3px " + Colors$BsConsole.octothorpe(Colors$BsConsole.blackA12)))))),
                /* :: */[
                  Css.unsafe("height", "unset"),
                  /* :: */[
                    Css.position(/* fixed */10615156),
                    /* :: */[
                      Css.top(Css.px(12)),
                      /* :: */[
                        Css.unsafe("left", "calc(50vw - 440px)"),
                        /* :: */[
                          Css.width(Css.px(880)),
                          /* :: */[
                            Css.zIndex(10),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]);
  } else {
    return "";
  }
}

function setActionContainer(isFilterOpen) {
  return Css.merge(/* :: */[
              Css.style(/* :: */[
                    Css.display(/* flex */-1010954439),
                    /* :: */[
                      Css.alignItems(/* center */98248149),
                      /* :: */[
                        Css.height(Css.px(42)),
                        /* [] */0
                      ]
                    ]
                  ]),
              /* :: */[
                isFilterOpen ? Css.style(/* :: */[
                        Css.alignSelf(/* flexStart */662439529),
                        /* [] */0
                      ]) : "",
                /* [] */0
              ]
            ]);
}

var loadingSpinner = Css.style(/* :: */[
      Css.marginRight(Css.px(8)),
      /* [] */0
    ]);

var Style = {
  setContainerFocusStyle: setContainerFocusStyle,
  setActionContainer: setActionContainer,
  loadingSpinner: loadingSpinner
};

function autoFocus(inputRef) {
  if (inputRef === undefined) {
    return ;
  }
  var r = Caml_option.valFromOption(inputRef);
  try {
    r.querySelector("input").focus();
    return r.querySelector("input").select();
  }
  catch (exn){
    return ;
  }
}

function removeFocus(inputRef) {
  if (inputRef === undefined) {
    return ;
  }
  try {
    return Caml_option.valFromOption(inputRef).querySelector("input").blur();
  }
  catch (exn){
    return ;
  }
}

function FilterInputControl(Props) {
  var isFilterOpen = Props.isFilterOpen;
  var openFilter = Props.openFilter;
  var closeFilter = Props.closeFilter;
  var route = Props.route;
  var describeRemote = Props.describeRemote;
  var describeWithUnusedRemote = Props.describeWithUnusedRemote;
  var onChange = Props.onChange;
  var projectName = Props.projectName;
  var token = Props.token;
  var handleChangeUrl = Props.handleChangeUrl;
  var aperture = Props.aperture;
  var havings = Props.havings;
  var attribute = Props.attribute;
  var valueOpt = Props.value;
  var filterBarClassNameOpt = Props.filterBarClassName;
  var onContainerFocusedStyleOpt = Props.onContainerFocusedStyle;
  var saveRecentAttribute = Props.saveRecentAttribute;
  var config = Props.config;
  var queryBuilderOpen = Props.queryBuilderOpen;
  var openQueryBuilder = Props.openQueryBuilder;
  var closeQueryBuilder = Props.closeQueryBuilder;
  var queryBuilderState = Props.queryBuilderState;
  var setQueryBuilderState = Props.setQueryBuilderState;
  var isEditing = Props.isEditing;
  var setIsEditing = Props.setIsEditing;
  var isNLQ = Props.isNLQ;
  var setNLQ = Props.setNLQ;
  var value = valueOpt !== undefined ? Caml_option.valFromOption(valueOpt) : undefined;
  var filterBarClassName = filterBarClassNameOpt !== undefined ? filterBarClassNameOpt : "";
  var onContainerFocusedStyle = onContainerFocusedStyleOpt !== undefined ? onContainerFocusedStyleOpt : setContainerFocusStyle(isFilterOpen);
  var filterContainerRef = React.useRef(undefined);
  var match = React.useState((function () {
          return [];
        }));
  var setSuggestedQuestions = match[1];
  var match$1 = React.useState((function () {
          return [];
        }));
  var setRecentQuestions = match$1[1];
  var recentQuestions = match$1[0];
  var match$2 = React.useState((function () {
          return false;
        }));
  var filterArray = Curry._1(Crdb$BsConsole.Filters.toArray, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture));
  var triageFilters = Belt_Array.keep(filterArray, (function (f) {
          return InboxWorkflow$BsConsole.Folders.isInboxWorkflow(f);
        }));
  var nonTriageFilters = Belt_Array.keep(filterArray, (function (f) {
          return !InboxWorkflow$BsConsole.Folders.isInboxWorkflow(f);
        }));
  var filters = Belt_Array.concat(triageFilters, nonTriageFilters);
  var handleAddRecentQuestion = function (question) {
    if (question === "") {
      return ;
    }
    var newRecentQuestions = Belt_Array.slice(Belt_Array.concat([question], recentQuestions), 0, 3);
    UserFrontendSettings$BsConsole.updateOrCreate(token, config.user.uid, TeamsUtils$BsConsole.isGuest(config), (function (settings) {
            if (settings !== undefined) {
              return {
                      projectDefaultViews: settings.projectDefaultViews,
                      bookmarkedViews: settings.bookmarkedViews,
                      reopenLatestSelectedAttribute: settings.reopenLatestSelectedAttribute,
                      reopenLatestSelectedType: settings.reopenLatestSelectedType,
                      reopenLatestAttributeValues: settings.reopenLatestAttributeValues,
                      reopenLatestSelectedTimePresent: settings.reopenLatestSelectedTimePresent,
                      unityCDA: settings.unityCDA,
                      firstTimeExperience: settings.firstTimeExperience,
                      highlightbarAttributes: settings.highlightbarAttributes,
                      debugViewToggles: settings.debugViewToggles,
                      languageCode: settings.languageCode,
                      fingerprintDetailsAttrs: settings.fingerprintDetailsAttrs,
                      nlqRecentQuestions: newRecentQuestions
                    };
            } else {
              return {
                      projectDefaultViews: UserFrontendSettings$BsConsole.empty.projectDefaultViews,
                      bookmarkedViews: UserFrontendSettings$BsConsole.empty.bookmarkedViews,
                      reopenLatestSelectedAttribute: UserFrontendSettings$BsConsole.empty.reopenLatestSelectedAttribute,
                      reopenLatestSelectedType: UserFrontendSettings$BsConsole.empty.reopenLatestSelectedType,
                      reopenLatestAttributeValues: UserFrontendSettings$BsConsole.empty.reopenLatestAttributeValues,
                      reopenLatestSelectedTimePresent: UserFrontendSettings$BsConsole.empty.reopenLatestSelectedTimePresent,
                      unityCDA: UserFrontendSettings$BsConsole.empty.unityCDA,
                      firstTimeExperience: UserFrontendSettings$BsConsole.empty.firstTimeExperience,
                      highlightbarAttributes: UserFrontendSettings$BsConsole.empty.highlightbarAttributes,
                      debugViewToggles: UserFrontendSettings$BsConsole.empty.debugViewToggles,
                      languageCode: UserFrontendSettings$BsConsole.empty.languageCode,
                      fingerprintDetailsAttrs: UserFrontendSettings$BsConsole.empty.fingerprintDetailsAttrs,
                      nlqRecentQuestions: newRecentQuestions
                    };
            }
          }), (function (param) {
            
          }), (function (err) {
            var __x = Backtrace$BsConsole.Attributes.addString("src_module", "FilterInputControl.re/handleAddRecentQuestion", Backtrace$BsConsole.Attributes.make(undefined));
            return Backtrace$BsConsole.Client.send(__x, /* `string */[
                        288368849,
                        "Failed to persist fingerprint details attrs: " + err
                      ]);
          }));
    return Curry._1(setRecentQuestions, (function (param) {
                  return newRecentQuestions;
                }));
  };
  React.useEffect((function () {
          var recentQuestions = Belt_Option.getWithDefault(Belt_Option.flatMap(config.userSettings, (function (settings) {
                      return settings.nlqRecentQuestions;
                    })), []);
          Curry._1(setRecentQuestions, (function (param) {
                  return recentQuestions;
                }));
          
        }), [config]);
  var query_000 = Crdb$BsConsole.Aperture.$$default;
  var query_001 = /* Custom */["backtrace.agent"];
  var query_002 = Crdb$BsConsole.Fold.empty;
  var query_003 = /* Count */Block.__(1, [/* Descending */1]);
  var query = /* Aggregate */Block.__(0, [
      query_000,
      query_001,
      query_002,
      query_003,
      undefined,
      undefined
    ]);
  var match$3 = QueryPaginationTask$BsConsole.CrdbAggregate.use(token, projectName, query, "backtrace.agent", 1, undefined);
  var paginationRemote = match$3[0];
  React.useEffect((function () {
          if (typeof paginationRemote !== "number" && !paginationRemote.tag) {
            var group = Belt_List.get(paginationRemote[0][0][1], 0);
            if (group !== undefined) {
              var suggestedQuestions = SuggestedQuestionDefaults$BsConsole.sdkVariantToQuestions(SuggestedQuestionDefaults$BsConsole.sdkStringToVariant(group.factor.value));
              Curry._1(setSuggestedQuestions, (function (param) {
                      return suggestedQuestions;
                    }));
            }
            
          }
          
        }), [paginationRemote]);
  React.useEffect((function () {
          if (isFilterOpen && isEditing === false) {
            setTimeout((function (param) {
                    return autoFocus(filterContainerRef.current);
                  }), 100);
          }
          
        }), /* tuple */[
        filterContainerRef,
        isFilterOpen,
        queryBuilderOpen,
        isEditing
      ]);
  React.useEffect((function () {
          if (isFilterOpen) {
            
          } else {
            removeFocus(filterContainerRef.current);
          }
          
        }), /* tuple */[
        isFilterOpen,
        filterContainerRef
      ]);
  var tmp;
  tmp = isFilterOpen && queryBuilderOpen && !(typeof describeWithUnusedRemote === "number" || describeWithUnusedRemote.tag) ? React.createElement(FilterQueryBuilder$BsConsole.make, {
          aperture: aperture,
          havings: havings,
          token: token,
          projectName: projectName,
          describeAttributes: describeWithUnusedRemote[0],
          onChange: (function (aperture) {
              return Curry._2(onChange, aperture, havings);
            }),
          closeQueryBuilder: closeQueryBuilder,
          setQueryBuilderState: setQueryBuilderState,
          saveRecentAttribute: saveRecentAttribute
        }) : null;
  return React.createElement(FilterContainer$BsConsole.make, {
              isFilterOpen: isFilterOpen,
              openFilter: openFilter,
              closeFilter: closeFilter,
              queryBuilderOpen: queryBuilderOpen,
              filterContainerRef: filterContainerRef,
              onContainerFocusedStyle: onContainerFocusedStyle,
              filterBarClassName: filterBarClassName,
              autoFocus: autoFocus,
              children: React.createElement(Col2$BsConsole.make, {
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ]),
                    children: null
                  }, React.createElement(Row2$BsConsole.make, {
                        className: Css.style(/* :: */[
                              Css.width(Css.pct(100)),
                              /* [] */0
                            ]),
                        children: null
                      }, Gate$BsConsole.shouldShowNLQ(undefined) ? React.createElement(NlqSwitch$BsConsole.make, {
                              isNLQ: isNLQ,
                              setNLQ: setNLQ,
                              filterContainerRef: filterContainerRef,
                              autoFocus: autoFocus
                            }) : React.createElement(FilterBarIcon$BsConsole.make, {
                              icon: React.createElement(FilterList.default, { }),
                              text: "",
                              styles: Css.style(/* :: */[
                                    Css.color(Css.hex(Colors$BsConsole.grey5)),
                                    /* :: */[
                                      Css.height(Css.px(42)),
                                      /* [] */0
                                    ]
                                  ])
                            }), React.createElement(FilterChips$BsConsole.make, {
                            config: config,
                            route: route,
                            token: token,
                            projectName: projectName,
                            describeRemote: describeRemote,
                            aperture: aperture,
                            attribute: attribute,
                            value: value,
                            havings: havings,
                            filters: filters,
                            filterArray: filterArray,
                            filterContainerRef: filterContainerRef,
                            isFilterOpen: isFilterOpen,
                            isEditing: isEditing,
                            isNLQ: isNLQ,
                            recentQuestions: recentQuestions,
                            handleAddRecentQuestion: handleAddRecentQuestion,
                            suggestedQuestions: match[0],
                            setNlqLoading: match$2[1],
                            closeFilter: closeFilter,
                            setIsEditing: setIsEditing,
                            queryBuilderOpen: queryBuilderOpen,
                            queryBuilderState: queryBuilderState,
                            openQueryBuilder: openQueryBuilder,
                            handleChangeUrl: handleChangeUrl,
                            onChange: onChange,
                            saveRecentAttribute: saveRecentAttribute,
                            autoFocus: autoFocus
                          }), React.createElement("div", {
                            className: setActionContainer(isFilterOpen) + " smaller-icon"
                          }, Gate$BsConsole.shouldShowNLQ(undefined) && match$2[0] ? React.createElement(CircularProgress.default, {
                                  size: 20,
                                  color: "primary",
                                  className: loadingSpinner
                                }) : React.createElement(React.Fragment, undefined, React.createElement(TotalFilterChipsCount$BsConsole.make, {
                                      isFilterOpen: isFilterOpen,
                                      filtersCount: filters.length,
                                      havingsCount: Curry._1(Crdb$BsConsole.Havings.toArray, havings).length
                                    }), React.createElement(ClearFiltersButton$BsConsole.make, {
                                      aperture: aperture,
                                      filterArray: filterArray,
                                      isFilterOpen: isFilterOpen,
                                      onChange: onChange,
                                      filterContainerRef: filterContainerRef,
                                      autoFocus: autoFocus
                                    })))), tmp)
            });
}

var make = FilterInputControl;

exports.Style = Style;
exports.autoFocus = autoFocus;
exports.removeFocus = removeFocus;
exports.make = make;
/* loadingSpinner Not a pure module */
