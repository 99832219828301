// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var HtmlElementRe = require("bs-webapi/lib/js/src/dom/html/HtmlElementRe.js");
var Crdb$BsConsole = require("../crdb.js");
var I18N$BsConsole = require("../I18N.js");
var Util$BsConsole = require("../util.js");
var Colors$BsConsole = require("../Colors.js");
var InputChip$BsConsole = require("../InputChip.js");
var FilterInput$BsConsole = require("../FilterInput.js");
var CoronerdGate$BsConsole = require("../CoronerdGate.js");
var ResizeObserver$BsConsole = require("../ResizeObserver.js");

function FilterChips(Props) {
  var config = Props.config;
  var route = Props.route;
  var token = Props.token;
  var projectName = Props.projectName;
  var describeRemote = Props.describeRemote;
  var aperture = Props.aperture;
  var attribute = Props.attribute;
  var value = Props.value;
  var havings = Props.havings;
  var filters = Props.filters;
  var filterArray = Props.filterArray;
  var filterContainerRef = Props.filterContainerRef;
  var isFilterOpen = Props.isFilterOpen;
  var isEditing = Props.isEditing;
  var isNLQ = Props.isNLQ;
  var recentQuestions = Props.recentQuestions;
  var handleAddRecentQuestion = Props.handleAddRecentQuestion;
  var suggestedQuestions = Props.suggestedQuestions;
  var setNlqLoading = Props.setNlqLoading;
  var closeFilter = Props.closeFilter;
  var setIsEditing = Props.setIsEditing;
  var queryBuilderOpen = Props.queryBuilderOpen;
  var queryBuilderState = Props.queryBuilderState;
  var openQueryBuilder = Props.openQueryBuilder;
  var handleChangeUrl = Props.handleChangeUrl;
  var onChange = Props.onChange;
  var saveRecentAttribute = Props.saveRecentAttribute;
  var autoFocus = Props.autoFocus;
  var scrollbarStyle = Css.style(/* :: */[
        Css.selector("::-webkit-scrollbar", /* :: */[
              Css.backgroundColor(Css.hex(Colors$BsConsole.grey3)),
              /* :: */[
                Css.height(Css.px(7)),
                /* :: */[
                  Css.cursor(/* pointer */-786317123),
                  /* [] */0
                ]
              ]
            ]),
        /* :: */[
          Css.selector("::-webkit-scrollbar-thumb", /* :: */[
                Css.important(Css.background(Css.hex(Colors$BsConsole.white))),
                /* :: */[
                  Css.borderRadius(Css.px(50)),
                  /* [] */0
                ]
              ]),
          /* [] */0
        ]
      ]);
  var setFilterInputRowStyle = function (isFilterOpen) {
    var openStyles = Css.style(/* :: */[
          Css.important(Css.flexWrap(/* wrap */-822134326)),
          /* :: */[
            Css.overflowY(/* scroll */-949692403),
            /* :: */[
              Css.height(/* auto */-1065951377),
              /* [] */0
            ]
          ]
        ]);
    return Css.merge(/* :: */[
                Css.style(/* :: */[
                      Css.display(/* flex */-1010954439),
                      /* :: */[
                        Css.flexGrow(1),
                        /* :: */[
                          Css.alignItems(/* center */98248149),
                          /* :: */[
                            Css.flexWrap(/* nowrap */867913355),
                            /* :: */[
                              Css.minHeight(Css.px(42)),
                              /* :: */[
                                Css.paddingTop(Css.px(5)),
                                /* :: */[
                                  Css.paddingBottom(Css.px(5)),
                                  /* :: */[
                                    Css.overflowX(/* hidden */-862584982),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]),
                /* :: */[
                  scrollbarStyle,
                  /* :: */[
                    isFilterOpen ? openStyles : "",
                    /* [] */0
                  ]
                ]
              ]);
  };
  var getHavingInfo = function (having) {
    var operator = Crdb$BsConsole.HavingOperation.toString(Curry._1(Crdb$BsConsole.Having.getOperation, having));
    var attribute = Belt_Option.getWithDefault(Curry._1(Crdb$BsConsole.Having.getAttribute, having), "");
    var value = Json_decode.string(Crdb$BsConsole.HavingOperation.getValue(Curry._1(Crdb$BsConsole.Having.getOperation, having)));
    var value$1 = attribute === "_tx" ? Util$BsConsole.decToHex(value) : value;
    return /* tuple */[
            attribute,
            operator,
            value$1
          ];
  };
  var getFilterInfo = function (filter) {
    var operator = Crdb$BsConsole.FilterOperation.toString(Curry._1(Crdb$BsConsole.Filter.getOperation, filter));
    var attribute = Curry._1(Crdb$BsConsole.Filter.getAttribute, filter);
    var value = Json_decode.string(Crdb$BsConsole.FilterOperation.valueToJson(Crdb$BsConsole.FilterOperation.getValue(Curry._1(Crdb$BsConsole.Filter.getOperation, filter))));
    var value$1 = attribute === "_tx" ? Util$BsConsole.decToHex(value) : value;
    return /* tuple */[
            attribute,
            operator,
            value$1
          ];
  };
  var focusChipDelete = function (param) {
    if (isFilterOpen) {
      setTimeout((function (param) {
              return Curry._1(autoFocus, filterContainerRef.current);
            }), 300);
      return ;
    } else {
      setTimeout((function (param) {
              var nodeList = document.querySelectorAll(".filter-delete");
              var node = nodeList.item(0);
              if (node == null) {
                return ;
              }
              var element = Curry._1(HtmlElementRe.ofNode, node);
              if (element !== undefined) {
                Caml_option.valFromOption(element).focus();
                return ;
              }
              
            }), 300);
      return ;
    }
  };
  var filterChipsContainerRef = React.useRef(null);
  var filterChipsContainerBoundingRect = ResizeObserver$BsConsole.use(filterChipsContainerRef);
  var totalChipCount = Curry._1(Crdb$BsConsole.Havings.toArray, havings).length + filterArray.length | 0;
  var tmp;
  var exit = 0;
  if (isEditing || !(isFilterOpen || totalChipCount <= 0)) {
    tmp = null;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement(FilterInput$BsConsole.make, {
          isEditing: isEditing,
          route: route,
          primaryPlaceholder: I18N$BsConsole.get(undefined, "Add filters"),
          describeRemote: describeRemote,
          onFilterChange: (function (newFilter) {
              Curry._1(saveRecentAttribute, newFilter);
              var __x = Curry._2(Crdb$BsConsole.Filters.add, newFilter, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture));
              Curry._2(onChange, Curry._2(Crdb$BsConsole.Aperture.setFilters, __x, aperture), havings);
              if (isFilterOpen) {
                setTimeout((function (param) {
                        return Curry._1(autoFocus, filterContainerRef.current);
                      }), 500);
                return ;
              }
              
            }),
          onHavingChange: (function (newHaving) {
              var nextHavings = CoronerdGate$BsConsole.isEnoughCoronerdVersion("1.49.1") ? Curry._2(Crdb$BsConsole.Havings.add, newHaving, havings) : Curry._2(Crdb$BsConsole.Havings.add, newHaving, Crdb$BsConsole.Havings.empty);
              Curry._2(onChange, aperture, nextHavings);
              if (isFilterOpen) {
                setTimeout((function (param) {
                        return Curry._1(autoFocus, filterContainerRef.current);
                      }), 500);
                return ;
              }
              
            }),
          onDelete: focusChipDelete,
          projectName: projectName,
          handleChangeUrl: handleChangeUrl,
          containerFocused: isFilterOpen,
          aperture: aperture,
          havings: havings,
          attribute: attribute,
          operator: undefined,
          value: value,
          openQueryBuilder: openQueryBuilder,
          queryBuilderState: queryBuilderState,
          queryBuilderOpen: queryBuilderOpen,
          config: config,
          token: token,
          isNLQ: isNLQ,
          suggestedQuestions: suggestedQuestions,
          recentQuestions: recentQuestions,
          handleAddRecentQuestion: handleAddRecentQuestion,
          setNlqLoading: setNlqLoading,
          closeFilter: closeFilter,
          filterChipsContainerHeight: filterChipsContainerBoundingRect.height
        });
  }
  return React.createElement("div", {
              ref: filterChipsContainerRef,
              className: setFilterInputRowStyle(isFilterOpen)
            }, Belt_Array.mapWithIndex(Curry._1(Crdb$BsConsole.Havings.toArray, havings), (function (idx, item) {
                    var match = getHavingInfo(item);
                    return React.createElement(InputChip$BsConsole.Filter.make, {
                                route: route,
                                isHidden: false,
                                isFilterOpen: isFilterOpen,
                                describeRemote: describeRemote,
                                handleChangeUrl: handleChangeUrl,
                                projectName: projectName,
                                token: token,
                                aperture: aperture,
                                havings: havings,
                                onDelete: focusChipDelete,
                                isEditable: true,
                                setIsEditing: Curry.__1(setIsEditing),
                                item: /* Having */Block.__(0, [item]),
                                onChange: onChange,
                                filterChipsContainerHeight: filterChipsContainerBoundingRect.height,
                                closeFilter: closeFilter,
                                config: config,
                                key: match[0] + ("-" + (match[1] + ("-" + (match[2] + ("__" + String(idx))))))
                              });
                  })), Belt_Array.mapWithIndex(filters, (function (idx, item) {
                    var match = getFilterInfo(item);
                    return React.createElement(InputChip$BsConsole.Filter.make, {
                                route: route,
                                isHidden: false,
                                isFilterOpen: isFilterOpen,
                                describeRemote: describeRemote,
                                handleChangeUrl: handleChangeUrl,
                                projectName: projectName,
                                token: token,
                                aperture: aperture,
                                havings: havings,
                                onDelete: focusChipDelete,
                                isEditable: true,
                                setIsEditing: Curry.__1(setIsEditing),
                                item: /* Filter */Block.__(1, [item]),
                                onChange: onChange,
                                filterChipsContainerHeight: filterChipsContainerBoundingRect.height,
                                closeFilter: closeFilter,
                                config: config,
                                key: match[0] + ("-" + (match[1] + ("-" + (match[2] + ("__" + String(idx))))))
                              });
                  })), tmp);
}

var make = FilterChips;

exports.make = make;
/* Css Not a pure module */
