// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Crdb$BsConsole = require("./crdb.js");
var Task$BsConsole = require("./task.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var ApiDescribe$BsConsole = require("./apiDescribe.js");
var TaskCallback$BsConsole = require("./TaskCallback.js");
var Toolbar = require("@material-ui/core/Toolbar");
var FilterInputControl$BsConsole = require("./FilterInputControl.js");
var FilterQueryBuilder$BsConsole = require("./FilterQueryBuilder.js");

var toolbar = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.flexWrap(/* nowrap */867913355),
        /* :: */[
          Css.unsafe("overflow", "inherit"),
          /* :: */[
            Css.alignItems(/* center */98248149),
            /* :: */[
              Css.position(/* relative */903134412),
              /* :: */[
                Css.flexGrow(1),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var Style = {
  toolbar: toolbar
};

function sortAttributes(removeUnused, attributes) {
  var filteredAttributes = Belt_List.keep(attributes, (function (attr) {
          var match = attr.name;
          if (match === "fingerprint;issues;assignee") {
            return Belt_Option.getWithDefault(Belt_Option.map(Belt_List.getBy(attributes, (function (attribute) {
                                  return attribute.name === "fingerprint;issues;ticket";
                                })), (function (attribute) {
                              return attribute.statistics.used;
                            })), false);
          } else if (removeUnused && !attr.custom) {
            return attr.statistics.used;
          } else {
            return true;
          }
        }));
  var sortedCustom = Belt_List.sort(Belt_List.keep(filteredAttributes, (function (attr) {
              return attr.custom;
            })), (function (a, b) {
          return Caml_primitive.caml_string_compare($$String.lowercase(a.name), $$String.lowercase(b.name));
        }));
  var sortedbuiltIns = Belt_List.sort(Belt_List.keep(filteredAttributes, (function (attr) {
              return !attr.custom;
            })), (function (a, b) {
          return Caml_primitive.caml_string_compare($$String.lowercase(a.name), $$String.lowercase(b.name));
        }));
  return Pervasives.$at(sortedCustom, sortedbuiltIns);
}

function useDescribeRemote(removeUnusedOpt, token, name, handleTask) {
  var removeUnused = removeUnusedOpt !== undefined ? removeUnusedOpt : true;
  var match = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setRemote = match[1];
  var remote = match[0];
  React.useEffect((function () {
          if (typeof remote === "number") {
            if (remote !== 0) {
              Curry._2(handleTask, (function (json) {
                      try {
                        var arg = ApiDescribe$BsConsole.decodeExn(TaskCallback$BsConsole.getJson(json));
                        var results = sortAttributes(removeUnused, arg);
                        return Curry._1(setRemote, (function (param) {
                                      return /* Success */Block.__(0, [results]);
                                    }));
                      }
                      catch (raw_err){
                        var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                        console.log(/* tuple */[
                              "Failed to parse describe in FilterShell",
                              err
                            ]);
                        return Curry._1(setRemote, (function (param) {
                                      return /* Failure */Block.__(1, ["Failed to parse attributes"]);
                                    }));
                      }
                    }), Task$BsConsole.makeTask(/* Describe */Block.__(3, [
                          token,
                          name
                        ])));
            } else {
              Curry._1(setRemote, (function (param) {
                      return /* Loading */1;
                    }));
            }
          }
          
        }), [remote]);
  return remote;
}

var intialQueryBuilderState = /* tuple */[
  undefined,
  undefined,
  undefined
];

var initialState = {
  isFilterOpen: false,
  queryBuilderState: intialQueryBuilderState,
  queryBuilderOpen: false,
  isEditing: false,
  isNLQ: true
};

function reducer(state, action) {
  if (typeof action === "number") {
    switch (action) {
      case /* OpenFilter */0 :
          return {
                  isFilterOpen: true,
                  queryBuilderState: state.queryBuilderState,
                  queryBuilderOpen: state.queryBuilderOpen,
                  isEditing: state.isEditing,
                  isNLQ: state.isNLQ
                };
      case /* CloseFilter */1 :
          return {
                  isFilterOpen: false,
                  queryBuilderState: intialQueryBuilderState,
                  queryBuilderOpen: false,
                  isEditing: state.isEditing,
                  isNLQ: state.isNLQ
                };
      case /* OpenQueryBuilder */2 :
          return {
                  isFilterOpen: state.isFilterOpen,
                  queryBuilderState: state.queryBuilderState,
                  queryBuilderOpen: true,
                  isEditing: state.isEditing,
                  isNLQ: state.isNLQ
                };
      case /* CloseQueryBuilder */3 :
          return {
                  isFilterOpen: state.isFilterOpen,
                  queryBuilderState: intialQueryBuilderState,
                  queryBuilderOpen: false,
                  isEditing: state.isEditing,
                  isNLQ: state.isNLQ
                };
      
    }
  } else {
    switch (action.tag | 0) {
      case /* SetQueryBuilderState */0 :
          return {
                  isFilterOpen: state.isFilterOpen,
                  queryBuilderState: action[0],
                  queryBuilderOpen: state.queryBuilderOpen,
                  isEditing: state.isEditing,
                  isNLQ: state.isNLQ
                };
      case /* SetIsEditing */1 :
          var isEditing = action[0];
          return {
                  isFilterOpen: state.isFilterOpen,
                  queryBuilderState: isEditing ? intialQueryBuilderState : state.queryBuilderState,
                  queryBuilderOpen: isEditing ? false : state.queryBuilderOpen,
                  isEditing: isEditing,
                  isNLQ: state.isNLQ
                };
      case /* SetNLQ */2 :
          return {
                  isFilterOpen: state.isFilterOpen,
                  queryBuilderState: state.queryBuilderState,
                  queryBuilderOpen: state.queryBuilderOpen,
                  isEditing: state.isEditing,
                  isNLQ: action[0]
                };
      
    }
  }
}

function FilterShell(Props) {
  var onChange = Props.onChange;
  var name = Props.name;
  var handleTask = Props.handleTask;
  var handleChangeUrl = Props.handleChangeUrl;
  var route = Props.route;
  var aperture = Props.aperture;
  var havings = Props.havings;
  var toolbarClassNameOpt = Props.toolbarClassName;
  var filterBarClassNameOpt = Props.filterBarClassName;
  var token = Props.token;
  var config = Props.config;
  var toolbarClassName = toolbarClassNameOpt !== undefined ? toolbarClassNameOpt : "";
  var filterBarClassName = filterBarClassNameOpt !== undefined ? filterBarClassNameOpt : "";
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var match$1 = match[0];
  var describeRemote = useDescribeRemote(undefined, token, name, handleTask);
  var describeWithUnusedRemote = useDescribeRemote(false, token, name, handleTask);
  var saveRecentAttribute = function (attribute) {
    var attributeName = Curry._1(Crdb$BsConsole.Filter.getAttribute, attribute);
    var operator = Crdb$BsConsole.FilterOperation.toString(Curry._1(Crdb$BsConsole.Filter.getOperation, attribute));
    return FilterQueryBuilder$BsConsole.RecentAttributes.save({
                project: name,
                attributeName: attributeName,
                operator: operator
              });
  };
  var handleKeyDown = function ($$event) {
    var el = $$event.target;
    var isTyping;
    if (el !== undefined) {
      var el$1 = Caml_option.valFromOption(el);
      isTyping = el$1.tagName === "INPUT" || el$1.tagName === "TEXTAREA" || Caml_obj.caml_equal(Caml_option.nullable_to_opt(el$1.getAttribute("contenteditable")), "true");
    } else {
      isTyping = false;
    }
    var match = $$event.key;
    if (isTyping) {
      return ;
    }
    switch (match) {
      case "/" :
          $$event.preventDefault();
          Curry._1(dispatch, /* SetNLQ */Block.__(2, [false]));
          return Curry._1(dispatch, /* OpenFilter */0);
      case "?" :
          $$event.preventDefault();
          Curry._1(dispatch, /* SetNLQ */Block.__(2, [true]));
          return Curry._1(dispatch, /* OpenFilter */0);
      default:
        return ;
    }
  };
  React.useEffect((function () {
          window.addEventListener("keydown", handleKeyDown);
          return (function (param) {
                    window.removeEventListener("keydown", handleKeyDown);
                    
                  });
        }), ([]));
  return React.createElement(Toolbar.default, {
              disableGutters: true,
              className: Css.merge(/* :: */[
                    toolbar,
                    /* :: */[
                      toolbarClassName,
                      /* [] */0
                    ]
                  ]),
              children: React.createElement(FilterInputControl$BsConsole.make, {
                    isFilterOpen: match$1.isFilterOpen,
                    openFilter: (function (param) {
                        return Curry._1(dispatch, /* OpenFilter */0);
                      }),
                    closeFilter: (function (param) {
                        return Curry._1(dispatch, /* CloseFilter */1);
                      }),
                    route: route,
                    describeRemote: describeRemote,
                    describeWithUnusedRemote: describeWithUnusedRemote,
                    onChange: Curry.__2(onChange),
                    projectName: name,
                    token: token,
                    handleChangeUrl: handleChangeUrl,
                    aperture: aperture,
                    havings: havings,
                    filterBarClassName: filterBarClassName,
                    saveRecentAttribute: saveRecentAttribute,
                    config: config,
                    queryBuilderOpen: match$1.queryBuilderOpen,
                    openQueryBuilder: (function (param) {
                        return Curry._1(dispatch, /* OpenQueryBuilder */2);
                      }),
                    closeQueryBuilder: (function (param) {
                        return Curry._1(dispatch, /* CloseQueryBuilder */3);
                      }),
                    queryBuilderState: match$1.queryBuilderState,
                    setQueryBuilderState: (function (v) {
                        return Curry._1(dispatch, /* SetQueryBuilderState */Block.__(0, [v]));
                      }),
                    isEditing: match$1.isEditing,
                    setIsEditing: (function (v) {
                        return Curry._1(dispatch, /* SetIsEditing */Block.__(1, [v]));
                      }),
                    isNLQ: match$1.isNLQ,
                    setNLQ: (function (v) {
                        return Curry._1(dispatch, /* SetNLQ */Block.__(2, [v]));
                      })
                  })
            });
}

function make(onChange, name, handleTask, handleChangeUrl, aperture, havings, route, token, filterBarClassName, config, children) {
  return ReasonReactCompat.wrapReactForReasonReact(FilterShell, {
              onChange: onChange,
              name: name,
              handleTask: handleTask,
              handleChangeUrl: handleChangeUrl,
              route: route,
              aperture: aperture,
              havings: havings,
              filterBarClassName: filterBarClassName,
              token: token,
              config: config
            }, children);
}

var Re = {
  make: make
};

var make$1 = FilterShell;

exports.Style = Style;
exports.sortAttributes = sortAttributes;
exports.useDescribeRemote = useDescribeRemote;
exports.intialQueryBuilderState = intialQueryBuilderState;
exports.initialState = initialState;
exports.reducer = reducer;
exports.make = make$1;
exports.Re = Re;
/* toolbar Not a pure module */
